<template>
<div style="display:none" class="loader"></div>
</template>

<script>
export default {
name:'LoaderHTML'
}
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

 
    position: absolute;
    bottom: 0;
    left: 600px;
    top: 240px;
}





@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>