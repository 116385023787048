import {LOGIN_TLOGIN_GETTER, GET_USER_DATA_GETTER,GET_USER_STORE_DATA_GETTER,CLEAR_UP_LOGIN_GETTER,CLEAR_USER_STORE_GETTER,GET_USER_LOGGED_IN_GETTER,CLEAR_UP_LOGIN_FLAG_GETTER } from "@/store/storeconstants";

export default {

     [GET_USER_STORE_DATA_GETTER]: (state) => {
        return state.UserStore
     },
     [GET_USER_DATA_GETTER]:(state)=>{
      return state.userLoggedin
     },
     [CLEAR_UP_LOGIN_GETTER]:(state)=>{
      return state.CurrentUserLogin
     } ,
     [GET_USER_LOGGED_IN_GETTER]:(state)=>{
       return state.userLoggedin
     },
     [CLEAR_USER_STORE_GETTER ]:(state)=>{
      return state.UserStore 
     } ,
     [CLEAR_UP_LOGIN_FLAG_GETTER]:(state)=>{
      return state.userLoggedin
     },

      [LOGIN_TLOGIN_GETTER]:(state)=>{
      return state.TokenLogin
     }
     

};