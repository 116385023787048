import {
  GET_CART_DATA_GETTER,
  GET_CART_QUANTITY_GETTER,
  GET_DELETE_SINGLE_ITEM_CART_GETTER,
  GET_DELETE_ALL_ITEMS_CART_GETTER,
  HELCIUM_PAYMENT_GETTER,
  CHECK_PAYMENT_GETTER,
  GET_CART_REVIEW_GETTER,
  PAYPAL_PAYMENT_GETTER, 
  CLEAR_CART_GETTER,
  DELETE_SINGLE_UPLOADED_CART_ARTWORK_GETTER
} from "@/store/storeconstants";

export default {
  [GET_CART_DATA_GETTER]: (state) => {
    return state.cart;
  },
  [GET_CART_QUANTITY_GETTER]: (state) => {
    return state.updatedCart;
  },
  [GET_DELETE_SINGLE_ITEM_CART_GETTER]: (state) => {
    return state.deletedSingleItem;
  },
  [GET_DELETE_ALL_ITEMS_CART_GETTER]: (state) => {
    return state.deleteAllItems;
  },

   [GET_CART_REVIEW_GETTER]: (state) => {
    return state.reviewPayment;
  },

  [HELCIUM_PAYMENT_GETTER]: (state) => {
    return state.HelciumPayment;
  },

  [CHECK_PAYMENT_GETTER]: (state) => {
    return state.CheckPayment;
  }, 

  [CLEAR_CART_GETTER]: (state) => {
    return state.cart;
  }, 

  [DELETE_SINGLE_UPLOADED_CART_ARTWORK_GETTER]: (state) => {
    return state.cart;
  }, 

  [PAYPAL_PAYMENT_GETTER]:(state)=>{
   return state.paypalPayment
  },
 
 
};
