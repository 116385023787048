

const myPlugin = {
    install(app) {
        app.config.globalProperties.$country = (key) => {
            return "My Country "+key;
        }
        
    }
}

export default myPlugin;