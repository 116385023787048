<template>
  <div class="email_subscription position-relative">
    <div class="container position-relative">
      <div class="row">
        <div class="col-md-7">
          <div class="">
            <h6 class="title text-capitalize text-white">Don’t wait... email today</h6>
            <div class="white_bg_btn mt-4">
              <a :href="'mailto:' + newslateEmail" class="btn btn-outline-white p-0"
                >Email us Today</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // return {
    //     newslateEmail: '',
    // }
  },
  setup() {
    let newslateEmail = "";
    const items = JSON.parse(localStorage.getItem("vuex"));
    newslateEmail = items?.homeData?.home?.footer?.newsletter_email;
    return {
      newslateEmail,
    };
  },
  computed: {},
};
</script>
