import Validations from "./Validations";

export default class SignupValidations {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    checkValidations() {
        let errors = [];
        //email validations
        if (!Validations.checkEmail(this.email)) {
            errors['email'] = 'Invalid Email';
        }

        //password Validations
        // if (!Validations.minLength(this.password, 6)) {
        //     errors['password'] = 'password must be at least 6 characters';
        // }

        return errors;
    }

    static getErrorMessageFromCode(msg) {
        switch (msg.message) {
            case 'Unauthorised':
                return 'Sorry you entered Invalid Credentials';
            case 'EMAIL_NOT_FOUND':
                return 'Email Not Found';
            default:
                return 'Unexpected error occurred. Please try again';
        }
    }

}