import { LOGIN_TLOGIN_MUTATION, SET_USER_LOGIN_DATA_MUTATION,FIRST_REGISTERED_MUTATION,SET_USER_LOGGED_IN_MUTATION, SET_USER_TOKEN_DATA_MUTATION,/*SET_USER_DATA_MUTATION*/SET_USER_STORE_DATA_MUTATION,CLEAR_UP_LOGIN_MUTATION,CLEAR_USER_STORE_MUTATION,CLEAR_UP_LOGIN_FLAG_MUTATION,LOGIN_CHOOSER_MUTATION} from "@/store/storeconstants";

export default {
    [SET_USER_TOKEN_DATA_MUTATION](state, payload) {
        state.signup = payload
    },
    [SET_USER_LOGIN_DATA_MUTATION](state, payload) {
       
        state.CurrentUserLogin = payload
       
    },
    [SET_USER_LOGGED_IN_MUTATION](state)
    {
      state.userLoggedin=true
    },
   
    [CLEAR_UP_LOGIN_MUTATION](state,payload){
      state.CurrentUserLogin=payload
    },
    [SET_USER_STORE_DATA_MUTATION](state, payload) {
               
        state.UserStore = payload
    },

    [CLEAR_USER_STORE_MUTATION](state)
    {
        state.UserStore={}
       
    },
    [CLEAR_UP_LOGIN_FLAG_MUTATION](state)
    {
        state.userLoggedin=false
    },

   [FIRST_REGISTERED_MUTATION](state,payload)
   {
      
       state.CurrentUserLogin.CurrentUserLogin=payload
   },

   [LOGIN_CHOOSER_MUTATION](state,payload)
   {
    state.UserStore=payload
   },

   [LOGIN_TLOGIN_MUTATION](state,payload)
   {
    state.TokenLogin=payload
   }




    
};