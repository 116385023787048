<template>
  <div
    class="modal fade"
    id="userlogin"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">User Login</h5>
          <button
            id="getbtnclose"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="form-horizontal" @submit.prevent="onLogin()" method="post">
            <div class="login-register-options">
              <ul>
                <li class="text-center">
                  <h4>Sign in</h4>
                  <p>
                    If you would like to continue on without logging in yet, simply tap
                    anywhere off the popup
                  </p>
                  <span
                    class="d-block font-11 ms-1 mt-1 text-danger text-center"
                    v-if="ServerSideError"
                  >
                    {{ ServerSideError }}
                  </span>
                </li>
                <li>
                  <div class="form-group">
                    <input
                      id="email"
                      type="text"
                      class="form-control"
                      placeholder="Email ID/Talent ID"
                      v-model.trim="formData.email"
                      autofocus
                      required
                      autocomplete="false"
                    />
                    <span
                      class="d-block font-11 ms-1 mt-1 text-danger text-start"
                      v-if="errors.email"
                    >
                      {{ errors.email }}
                    </span>
                  </div>

                  <div class="form-group">
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model.trim="formData.password"
                      autofocus
                      required
                      autocomplete="false"
                    />
                    <span
                      class="d-block font-11 ms-1 mt-1 text-danger text-start"
                      v-if="errors.password"
                    >
                      {{ errors.password }}
                    </span>
                  </div>
                  <p class="text-center">
                    <button
                      type="submit"
                      class="btn btn-template-main btn-block dopopuplogin"
                      id="login_form"
                    >
                      <i class="fa fa-sign-in"></i> Log in
                    </button>
                  </p>
                </li>
                <li></li>
                <li>
                  <a
                    class="btn btn-link"
                    @click.prevent="forgotpassword()"
                    data-bs-dismiss="modal"
                    :href="forgotpasswordlink"
                  >
                    Forgot Your Password? :
                  </a>
                </li>
                <li class="text-center">
                  By signing in, you agree to our <br /><a href="#">Conditions of Use</a>
                  and <a href="#">Privacy Policy</a>.
                </li>
                <li class="text-center">Or sign in with</li>
                <li>
                  <div class="row">
                    <div class="col-sm-6">
                      <a href="#" class="btn btn-template-main btn-block"
                        ><i class="fa fa-facebook"></i> Facebook</a
                      >
                    </div>
                    <div class="col-sm-6">
                      <a href="#" class="btn btn-template-main btn-block"
                        ><i class="fa fa-envelope"></i> Google</a
                      >
                    </div>
                  </div>
                </li>
                <li class="text-center">Or</li>
                <li>
                  <a
                    data-bs-dismiss="modal"
                    @click.prevent="hideLoginModalAfterClickRegister()"
                    class="btn btn-info btn-block"
                  >
                    Create a new Account
                  </a>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div style="display: none" class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Understood</button>
          <a id="loginchooser" data-bs-toggle="modal" data-bs-target="#login-choser"></a>
        </div>
      </div>
    </div>
  </div>

  <!----display user role selection modal upon login--->
  <LoginChooser :storeData="userData" />
  <!----display user role selection modal upon login--->
</template>

<script>
import { useStore } from "vuex";
import "bootstrap-vue/dist/bootstrap-vue.css";
import SignupValidations from "../services/SignupValidations";
import { mapActions } from "vuex";
import { LOGIN_ACTION, SET_USER_LOGGED_IN_ACTION } from "@/store/storeconstants";
import LoginChooser from "@/components/LoginChooser.vue";
// eslint-disable-next-line no-unused-vars
import { toast } from "vue3-toastify";

export default {
  name: "UserLogin",
  components: {
    LoginChooser,
  },

  data() {
    return {
      userData: [],
      formData: {
        email: "",
        password: "",
      },
      errors: [],
      ServerSideError: "",
      token: "",
      forgotpasswordlink: process.env.VUE_APP_BASE_URL_LOCAL + "/forgot-password",
    };
  },
  setup() {
    const store = useStore();
    let userStoreData = store.state.auth;
    let checkCart =
      store.state.cartData.cart != undefined ? store.state.cartData.cart.length : 0;

    return {
      userStoreData,
      checkCart,
    };
  },
  methods: {
    ...mapActions("auth", {
      login: LOGIN_ACTION,
    }),
    ...mapActions("auth", {
      loggedin: SET_USER_LOGGED_IN_ACTION,
    }),

    forgotpassword() {
      this.$router.push("forgot-password");
    },

    hideLoginModalAfterClickRegister() {
      this.$router.push("register");
    },

    onLogin() {
      // check email and password validations
      let validations = new SignupValidations(
        this.formData.email,
        this.formData.password
      );
      this.errors = validations.checkValidations();
      if ("email" in this.errors || "password" in this.errors) {
        return false;
      }
      let __that = this;
      // Login the user
      this.login(this.formData)
        .then((res) => {
          this.userData = res;
          localStorage.setItem("loginModalOpen", "false")
          this.loggedin();
          __that.formData = {
            email: "",
            password: "",
          };
          /***check if there is any cart item ***/

          // toast.success("Logged in successfully", { autoClose: 2400 });

          // setTimeout(() => {
          //   this.$router.push("/account");
          // }, 2800);

          /***check if there is any cart item ***/
        })
        .catch((ServerSideError) => {
          this.ServerSideError = ServerSideError;
          setTimeout(() => {
            this.ServerSideError = "";
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
.btn-template-main {
  color: #38a7bb !important;
  background-color: #ffffff !important;
  border-color: #38a7bb !important;
}

.btn {
  font-weight: 400 !important;
  font-family: "Roboto", Helvetica, Arial, sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em !important;
  padding: 6px 12px !important;
  font-size: 13px !important;
  line-height: 1.42857143 !important;
  border-radius: 2px !important;
  position: relative !important;
}

.btn-block {
  display: block !important;
  width: 100% !important;
}

.btn-template-main:hover {
  background: #38a7bb !important;
  color: #ffffff !important;
  border-color: #38a7bb !important;
  border-radius: 0px 15px 0px 15px !important;
}
</style>
