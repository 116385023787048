<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-center mb-0">
            <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Home</a
              >
            </li>
            <li class="breadcrumb-item">
              <a
                href="#/pages/printing"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >Printing</a
              >
            </li>
            <!-- <li class="breadcrumb-item">
              <a
                href="#"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
                >5.5" X 8.5" 50 Note pads</a
              >
            </li> -->
            <li
              class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
            >
              Cart
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <!-- Breadcrumb En d -->
  <!-- Process Steps Name Start -->
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul id="progress">
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            my shopping cart
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Shipping Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize finish"
          >
            Payment Details
          </li>
          <li
            class="font-14 font-family-Roboto font-weight-700 text-capitalize"
          >
            Checkout
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Process Steps Name End -->
  <div class="container">
    <div style="margin-bottom: 30px; margin-top: 30px">
      <div class="row">
        <h2 class="text-center mt-4 mb-4">
          <strong>Check/Cash Payment</strong>
        </h2>
        <div class="col-md-12 clearfix">
          <div class="input">
            <form
              ref="formData"
              method="POST"
              @submit.prevent="check_Payment()"
              accept-charset="UTF-8"
              id="OrderBillingDetailAuthBillingForm"
            >
              <input
                type="hidden"
                name="data[Order][id]"
                :value="getOrderId"
                id="OrderBillingDetailId"
              />
              <div class="form-group">
                <label for="OrderPayType"><strong>Pay Type</strong></label>
                <select
                  name="data[Order][pay_type]"
                  class="form-control"
                  id="OrderPayType"
                >
                  <option value="Check">Check</option>
                  <option value="Cash">Cash</option>
                </select>
              </div>
              <br />

              <div class="form-group">
                <label for="OrderPaymentData"
                  ><strong>Check Number</strong></label
                >
                <input
                  name="data[Order][payment_data]"
                  class="form-control"
                  type="text"
                  id="OrderPaymentData"
                />
              </div>
              <div class="submit">
                <input
                  class="btn btn-primary p-0 text-capitalize"
                  type="submit"
                  value="Save & Continue"
                />
              </div>
            </form>
          </div>

          <!-- /.col-md-12 -->
        </div>
        <!-- /.row -->
      </div>
    </div>
    <!-- /.container -->
  </div>
</template>
<script>
import $ from "jquery";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { mapActions /*,mapState, mapMutations*/ } from "vuex";
import {
  CHECK_PAYMENT_ACTION,
  CLEAR_CART_DATA_ACTION,
} from "../../store/storeconstants";
export default {
  name: "CheckPayment",
  data() {
    return {
      user_token: null,
      postData: {
        order_id: null,
        pay_type: null,
        check_number: null,
      },
    };
  },
  setup() {
    const store = useStore();
    let current_order_id =
      localStorage.getItem("current_order_id") !== null
        ? localStorage.getItem("current_order_id")
        : "";
    let cartData =
      store.state.cartData.cart != undefined ? store.state.cartData.cart : [];

    return {
      cartData,
      current_order_id,
    };
  },
  computed: {
    getOrderId() {
      let orderItems = this.cartData;
      let order_id = null;
      orderItems.map((e) => {
        order_id = e.order_id;
      });
      return order_id;
    },
  },

  mounted() {
    // console.log(this.cartData);
  },
  created(){
    this.user_token =
      this.$store.state.auth.CurrentUserLogin.token !== undefined
        ? this.$store.state.auth.CurrentUserLogin.token
        : this.$store.state.auth.CurrentUserLogin.CurrentUserLogin.token;
  },
  methods: {
    ...mapActions("cartData", {
      checkPayment: CHECK_PAYMENT_ACTION,
    }),

    ...mapActions("cartData", {
      clearCart: CLEAR_CART_DATA_ACTION,
    }),

    check_Payment() {

      let token = this.user_token;
      console.log("token get.. MD from ", token);

      let formData = new FormData();
      let formfields = this.$refs.formData;

      let orderidname = $(formfields).find("input[type='hidden']").attr("name");
      let paytypename = $(formfields).find("select").attr("name");
      let checknoname = $(formfields).find("input[type='text']").attr("name");

      let orderid = $(formfields).find("input[type='hidden']").val();
      let paytype = $(formfields).find("select :selected").val();
      let checkno = $(formfields).find("input[type='text']").val();

      formData.append(orderidname, orderid);
      formData.append(paytypename, paytype);
      formData.append(checknoname, checkno);

      let formDataWithToken = {
          formDataSend:formData, 
          token:token
      };

      this.checkPayment(formDataWithToken)
        .then((res) => {
          if (res.status == 200) {
            if (localStorage.getItem("ShippingFromDetails") !== null) {
              localStorage.removeItem("ShippingFromDetails");
            }

            if (localStorage.getItem("notes") !== null) {
              localStorage.removeItem("notes");
            }

            if (localStorage.getItem("ShippingToDetails") !== null) {
              localStorage.removeItem("ShippingToDetails");
            }

            if (localStorage.getItem("shipping_methods") !== null) {
              localStorage.removeItem("shipping_methods");
            }

            if (localStorage.getItem("product_option_map") !== null) {
              localStorage.removeItem("product_option_map");
            }

            if (localStorage.getItem("paymentMethod") !== null) {
              localStorage.removeItem("paymentMethod");
            }

            if (localStorage.getItem("current_order_id") !== null) {
              localStorage.removeItem("current_order_id");
            }

            if (localStorage.getItem("Tax") !== null) {
              localStorage.removeItem("Tax");
            }

            if (localStorage.getItem("tax") !== null) {
              localStorage.removeItem("tax");
            }

            if (localStorage.getItem("coupon") !== null) {
              localStorage.removeItem("coupon");
            }

            if (localStorage.getItem("discount") !== null) {
              localStorage.removeItem("discount");
            }

            this.clearCart([]);
            toast.success("Order created Successfully", { autoClose: 3200 });
            localStorage.setItem("isActive", "true");
            localStorage.setItem("isAccountPageReload", "true");
            setTimeout(() => {
              this.$router.push("/account");
            }, 4000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.btn.btn-primary.p-0.text-capitalize {
  margin-top: 20px;
  font-family: Roboto !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  width: 160px !important;
  height: 60px !important;
  line-height: 60px !important;
  background: 0/100% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 0.5s !important;
}
.btn.btn-primary.p-0.text-capitalize:hover {
  border-color: #7ec4cf !important;
}
.btn.btn-primary.p-0.text-capitalize:hover {
  color: #fff !important;
  border-color: #7ec4cf !important;
  border-radius: 2px !important;
  background: 0/100% 100% no-repeat linear-gradient(#7ec4cf, #7ec4cf) !important;
  transition: background-size 0.5s !important;
}
</style>
