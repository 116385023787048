<template>
  <div class="container mb-5 mt-3">
    <div class="row">
      <div class="col-md-12">
        <h2 id="accounttitle" class="text-start">My Account</h2>
      </div>
    </div>

    <div class="row mt-4 align-items-center">
      <div class="col-md-6">
        <ul id="accountaddress" class="nav nav-pills align-items-center">
          
          <li :class="isActive == 'true' ? 'active': ''">
            <a style="cursor: pointer" class="d-block" @click="accountname()"
              >Account Home</a
            >
          </li>
           
          <li :class="isActive == 'true' ? '': 'active'">
            <a
              id="addaddress"
              style="cursor: pointer"
              class="d-block"
              @click="address()"
              >Address Book</a
            >
          </li>
        </ul>
      </div>
      <div
      :class="isActive == 'true' ? 'noshow': ''"
      class="col-md-6 text-md-end mt-xs-5 mt-sm-2"
      >
        <a
          class="btn btn-dark rounded-0"
          data-bs-toggle="modal"
          data-bs-target="#add-address"
          style="cursor: pointer"
          >Add New Address</a
        >
      </div>
    </div>

    <!--end row-->
    <div class="row mt-4">
      <div class="col-sm-12">
        <div
          class="text-start p-4 mb-5"
          :class="isActive == 'true' ? '': 'noshow'"
          id="persondetails"
          v-if="userInfo.data && userInfo.data !== undefined"
        >
          <p>
            <label><strong>Full Name </strong></label> :
            {{ userInfo.data.first_name }}
            {{ userInfo.data.last_name }}
          </p>
          <p>
            <label><strong>Email </strong></label> : {{ userInfo.data.email }}
          </p>
          <p>
            <label><strong>User Since</strong></label> :
            {{ (d = new Date(userInfo.data.created_at)) }}
          </p>
        </div>

        <div
          v-else
          v-for="(user, index) in userInfo"
          v-bind:key="index"
          class="text-start p-4 mb-5"
          :class="isActive == 'true' ? '': 'noshow'"
          id="persondetails"
        >
          <p>
            <label><strong>Full Name </strong></label> :
            {{ user.data.first_name }}
            {{ user.data.last_name }}
          </p>
          <p>
            <label><strong>Email </strong></label> : {{ user.data.email }}
          </p>
          <p>
            <label><strong>User Since</strong></label> :
            {{ (d = new Date(user.data.created_at)) }}
          </p>
        </div>
      </div>

      <!-- <div class="col-sm-2" :class="{ noshow: showMe }"></div> -->
    </div>
    <!--end roww-->
     
    <div id="alltables" class="row" :class="isActive == 'true' ? '': 'noshow'">
      <div class="col-sm-12">
        <!--Other Orders-->
        <h2 class="text-start mb-3">Other Orders</h2>
        <div class="table-responsive">
          <table id="otherorders" class="table table-bordered mt-3 mb-5 w-100">
            <thead>
              <tr>
                <th>Order#</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!--Other Orders-->

        <!--printing Orders table start-->
        <h2 class="text-start mt-5 mb-4">Printing Orders</h2>
        <div class="table-responsive">
          <table
            id="printingorders"
            class="table table-bordered mt-5 mb-5 w-100"
          >
            <thead>
              <tr>
                <th>Order#</th>
                <th>Date</th>
                <th>Status</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!--printing Orders table end-->

        <!--commercial Orders table start-->
        <h2 class="text-start mt-5 mb-4">Commercial Orders</h2>
        <div class="table-responsive">
          <table
            id="commercialorders"
            class="table table-bordered mt-5 mb-5 w-100"
          >
            <thead>
              <tr>
                <th>Order#</th>
                <th>Date</th>
                <th>Status</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <!--commercial Orders table end-->
      </div>
    </div>
     

    <!----Address Book---->
    <div id="addresstable" class="row"  :class="isActive == 'true' ? 'noshow': ''">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table id="alladdress" class="table table-bordered mt-5 mb-5">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Address</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody @click="editAddress($event)"></tbody>
          </table>
        </div>
      </div>
      <!----Address Book---->
    </div>

    <!------Address popup modal----->
    <div
      class="modal fade"
      id="add-address"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <form
        @submit.prevent="addUserAddress($event)"
        name="user_address_submit"
        id="user_address"
        enctype="multipart/form-data"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-start" id="staticBackdropLabel">
                Add New Address
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="first_name" class="text-start"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="first_name"
                        placeholder="Enter First Name"
                        required=""
                        data-error-msg="The First Name is required!"
                      />
                      <div
                        class="alert alert-danger errors"
                        id="first_name_err"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="last_name" class="text-start"
                        >Last Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="last_name"
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="Title" class="text-start">Title</label>
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        placeholder="Enter Title"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="Company" class="text-start">Company</label>
                      <input
                        type="text"
                        class="form-control"
                        name="company"
                        placeholder="Enter Company"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="Address1" class="text-start">Address1</label>
                      <input
                        type="text"
                        class="form-control"
                        name="address1"
                        placeholder="Enter Address1"
                        required=""
                        data-error-msg="The Address1 is required!"
                      />
                      <div
                        class="alert alert-danger errors"
                        id="address1_err"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label for="Address2" class="text-start">Address2</label>
                      <input
                        type="text"
                        class="form-control"
                        name="address2"
                        placeholder="Enter Address2"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start"
                        >Country <span class="red">*</span></label
                      >
                      <select
                        @change.prevent="getaddCountry($event)"
                        name="addcountry"
                        class="form-control"
                        required=""
                        v-model="AddformData.country"
                        id="addcountry"
                      >
                        <option value="">Select Country</option>
                        <option
                          v-for="country in addcountries"
                          :value="country.id"
                          :key="country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="alert alert-danger errors"
                      id="country_err"
                      style="display: none"
                    ></div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start"
                        >State <span class="red">*</span></label
                      >
                      <select
                        required=""
                        v-model="AddformData.state"
                        @change.prevent="getaddState($event)"
                        name="add_state_id"
                        class="form-control"
                        id="add_state"
                      >
                        <option value="">Select State</option>
                        <option
                          v-for="state in addstates"
                          :key="state.id"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                      <div
                        class="alert alert-danger errors"
                        id="state_id_err"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start"
                        >City <span class="red">*</span></label
                      >
                      <select
                        required=""
                        v-model="AddformData.city"
                        @change.prevent="getaddCity($event)"
                        name="add_city"
                        class="form-control"
                        id="add_city"
                      >
                        <option value="">Select City</option>
                        <option
                          v-for="city in addcities"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                      <div
                        class="alert alert-danger errors"
                        id="city_err"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start" for="zip">Zip*</label>
                      <input
                        type="text"
                        class="form-control"
                        name="zip"
                        placeholder="Enter Zip"
                        required=""
                        data-error-msg="The Zip is required!"
                      />
                      <div
                        class="alert alert-danger errors"
                        id="zip_err"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start" for="day_phone"
                        >Day Phone</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="day_phone"
                        placeholder="Enter Day Phone"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-xs-12">
                    <div class="form-group">
                      <label class="text-start" for="night_phone"
                        >Night Phone</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="night_phone"
                        placeholder="Enter Night Phone"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-xs-12">
                    <div class="form-group">
                      <label class="text-start" for="email">Email</label>
                      <input
                        type="text"
                        required=""
                        class="form-control"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                id="addressbook_submit"
                name="addressbook_submit"
                value="addressbook_submit"
                class="btn btn-primary btn-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-----Address popup modal------>

    <!---Edit Address-->
    <div class="modal fade" id="edit-address" role="dialog">
      <div class="modal-dialog modal-lg">
        <form
          @submit.prevent="updateUserAddress()"
          name="address_submit"
          id="address"
          enctype="multipart/form-data"
        >
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Address</h4>
              <button
                id="closebtn"
                type="button"
                class="close"
                data-bs-dismiss="modal"
              >
                &times;
              </button>
              <div class="alert alert-danger errors hidden" id="ups_err"></div>
            </div>
            <div class="modal-body">
              <form method="POST" action="" accept-charset="UTF-8">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="first_name">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="first_name"
                        name="first_name"
                        :value="
                          userAddress.first_name != ''
                            ? userAddress.first_name
                            : ''
                        "
                        placeholder="Enter First Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="last_name">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="last_name"
                        name="last_name"
                        :value="
                          userAddress.last_name != ''
                            ? userAddress.last_name
                            : ''
                        "
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Title">Title</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="title"
                        name="title"
                        :value="
                          userAddress.title != '' ? userAddress.title : ''
                        "
                        placeholder="Enter Title"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Company">Company</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="company"
                        name="company"
                        :value="
                          userAddress.company != '' ? userAddress.company : ''
                        "
                        placeholder="Enter Company"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Address1">Address1</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="address1"
                        name="address1"
                        :value="
                          userAddress.address1 != '' ? userAddress.address1 : ''
                        "
                        placeholder="Enter Address1"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Address2">Address2</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="address2"
                        name="address2"
                        :value="
                          userAddress.address2 != '' ? userAddress.address2 : ''
                        "
                        placeholder="Enter Address2"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Country <span class="red">*</span></label>
                      <input
                        type="hidden"
                        id="country_id"
                        :value="userAddress.country"
                      />
                      <select
                        v-model="formData.country"
                        @change.prevent="getCountry($event)"
                        name="country"
                        class="form-control"
                        ref="country"
                        id="country"
                      >
                        <option value="">Select Country</option>
                        <option
                          v-for="country in countries"
                          :value="country.id"
                          :key="country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>State <span class="red">*</span></label>
                      <input
                        type="hidden"
                        id="states_id"
                        :value="userAddress.state"
                      />
                      <select
                        v-model="formData.state"
                        @change.prevent="getState($event)"
                        name="state_id"
                        class="form-control"
                        ref="state"
                        id="state"
                      >
                        <option value="">Select State</option>
                        <option
                          v-for="state in states"
                          :key="state.id"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>City<span class="red">*</span></label>
                      <input
                        type="hidden"
                        id="city_id"
                        :value="userAddress.city"
                      />
                      <select
                        v-model="formData.city"
                        @change.prevent="getCity($event)"
                        name="city"
                        class="form-control"
                        ref="city"
                        id="city"
                      >
                        <option value="">Select City</option>
                        <option
                          v-for="city in cities"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="zip">Zip</label>
                      <input
                        type="text"
                        class="form-control"
                        ref="zip"
                        name="zip"
                        :value="userAddress.zip != '' ? userAddress.zip : ''"
                        placeholder="Enter Zip"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="day_phone">Day Phone </label>
                      <input
                        type="text"
                        class="form-control"
                        ref="day_phone"
                        name="day_phone"
                        :value="
                          userAddress.dayphone ? userAddress.dayphone : ''
                        "
                        placeholder="Enter Day Phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="night_phone">Night Phone </label>
                      <input
                        type="text"
                        class="form-control"
                        ref="night_phone"
                        name="night_phone"
                        placeholder="Enter Night Phone"
                        :value="userAddress.nightphone ? userAddress.nightphone : ''"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        ref="email"
                        name="email"
                        placeholder="Enter Email"
                        :value="userAddress.email != '' ? userAddress.email : ''"
                      />
                    </div>
                  </div>
                  <div class="col-md-6"></div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div style="display: none" id="addresssuccess">
                <div class="alert alert-success"></div>
              </div>

              <div style="display: none" id="addresserror"></div>
              <!-- <input type="hidden" name="id" value="4"> -->
              <button
                type="submit"
                id="addressbook_submit"
                name="addressbook_submit"
                value="addressbook_submit"
                class="btn btn-primary btn-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!---Edit Address-->

    <!-- View Other Order Details modal Start -->
    <div
      class="modal fade"
      id="OtherOrderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="OtherOrderModalLabel"
      data-target="#OtherOrderModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="font-16 modal-title" id="OtherOrderModal">
              Order Details: #HSE/WEB/
              <span id="order-view_id"> </span>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                @click="closeOtherOrderModal('OtherOrderModal')"
                >×</span
              >
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th id="order-view_amount" class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name: <span id="order_name"></span></td>
                    <td class="text-right">
                      Company: <span id="order_companyname"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>Address 1: <span id="order_address1"></span></td>
                    <td class="text-right">
                      Address 2:
                      <span id="order_address2"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>City: <span id="order_city"></span></td>
                    <td class="text-right">
                      State: <span id="order_state"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>Zip: <span id="order_zip"></span></td>
                    <td class="text-right">
                      Phone: <span id="order_phone"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email: <span id="order_email"></span></td>
                    <td class="text-right"></td>
                  </tr>
                  <tr>
                    <td>Template#: <span id="order_template"></span></td>
                    <td class="text-right">$<span id="order_price"></span></td>
                  </tr>
                  <tr>
                    <td align="right"><b>Total</b></td>
                    <td align="right">
                      <b>$<span id="order_pricetotal"></span></b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- View Other Order Details modal End -->

    <!-- View Other Order Details modal Start -->
    <div
      class="modal fade"
      id="CommercialOrdersModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="CommercialOrdersLabel"
      data-target="#CommercialOrdersModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="font-16 modal-title" id="CommercialOrdersModal">
                Order Details: #HSE/WEB/
                <span id="orderViewId"> </span>
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                @click="closeOtherOrderModal('CommercialOrdersModal')"
                >×</span
              >
            </button>
          </div>
          <div class="modal-body pt-0 p-0">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="ps-3"> <strong>Last Change Date/Time:</strong> <span id="lastChangeDateTime"></span> </td>
                                <td class="text-right"><strong>Order Date/Time:</strong> <span id="orderDateTime"></span> </td>
                            </tr>

                            <tr>
                                <td class="ps-3"> <strong>Order  Number:</strong> <span id="OrderNumber"></span></td>
                                <td class="text-right"><strong>IP  Address:</strong>  <span id="IpAddress"></span> </td>
                            </tr>

                            <tr>
                                <td class="ps-3"> <strong>Customer Name:</strong> <span id="customerName"></span> </td>
                                <td class="text-right"> <strong>Customer Email:</strong> <span id="customerEmail"></span> </td>
                            </tr>

                            <tr>
                                <td class="ps-3"> <strong>Customer Number:</strong> <span id="CustomerNumber"></span> </td>
                                <td class="text-right">  <strong>Method:</strong> <span id="Method"></span> </td>
                            </tr>
                            <tr>
                                <td class="ps-3"> <strong>Price:</strong></td>
                                <td class="text-right"> : <span id="price"></span> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h4 class="mb-2 mt-5 ps-3">Invoice details</h4>
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="ps-3">Service/Product Name</th>
                                <th>Rate</th>
                                <th>Qty</th>
                                <th>Amount</th>
                                <th>Attchement</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="ps-3"> 1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                            </tr>
   
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- View Other Order Details modal End -->

  </div>
</template>

<script>
//import axios from "axios";

import { mapActions } from "vuex";
import $ from "jquery";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {
  GET_USER_PRINTING_ORDER_ACTION,
  // GET_USER_COMMERCIAL_ORDER_ACTION,
  GET_USER_SHIPPING_ADDRESS_ACTION,
  GET_COUNTRY_ACTION,
  GET_STATE_ACTION,
  GET_CITY_ACTION,
  CLEAR_UP_LOGIN_ACTION,
  CLEAR_UP_LOGIN_FLAG_ACTION,
  CLEAR_USER_STORE_ACTION,
  GETUPDATED_USER_SHIPPING_ADDRESS_ACTION,
} from "@/store/storeconstants";

export default {
  name: "MyAccountView",
  components: {
    // EditUserAddress,
  },

  data() {
    return {
      isActive: localStorage.getItem("isActive") != null ? localStorage.getItem("isActive") : 'true',
      name: "Yogesh Raghav",
      email: "yogeshragav3043@gmail.com",
      userInfo: [],
      user_since: new Date(),
      pagerefresh: 0,
      Orders: [],
      userAddressTable: [],
      orderPrintingTable: [],

      userAddress: {
        ship_id: null,
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        company: null,
        dayphone: null,
        nightphone: null,
        address1: null,
        address2: null,
        country: null,
        state: null,
        city: null,
        zip: null,
      },
      formData: {
        first_name: "",
        last_name: "",
        title: "",
        company: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        day_phone: "",
        night_phone: "",
        email: "",
      },
      AddformData: {
        first_name: "",
        last_name: "",
        title: "",
        company: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        day_phone: "",
        night_phone: "",
        email: "",
      },
      userToken: null,
      oldtoken: null,

      countries: ["Select Country"],
      states: [],
      cities: [],

      addcountries: ["Select Country"],
      addstates: [],
      addcities: [],

      countryid: this.$refs.country_id,
    };
  },

  beforeCreate() {
    // var tabless = $("#printingorders").DataTable();
    // var tables_commercialorders = $("#commercialorders").DataTable();
    // tabless.destroy();
    // tables_commercialorders.destroy();
    // $("#commercialorders").empty();
    // $("#printingorders").empty();
  },

  setup() {
    const store = useStore();
    const token = store.state.auth.CurrentUserLogin.CurrentUserLogin;
    let get_orders = store.state.userInfo;
    return {
      store,
      get_orders,
      token,
    };
  },

  computed: {
    getShippingAddresses() {
      let ship_address = this.getuserShippingAddress();
      return ship_address;
    },
  },

  beforeMount() {
    this.user_token =
      this.$store.state.auth.CurrentUserLogin.token !== undefined
        ? this.$store.state.auth.CurrentUserLogin.token
        : this.$store.state.auth.CurrentUserLogin.CurrentUserLogin.token;
    let addcountries = this.listCountries();
    addcountries
      .then((res) => {
        this.addcountries = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    //let allshipdata = this.getDefaultShipping;
  },

  mounted() {
    if (document.getElementById("loginchooserclose")) {
      document.getElementById("loginchooserclose").click();
    }
    this.userInfo =
      this.store.state.auth.UserStore !== undefined
        ? this.store.state.auth.UserStore
        : [];
  },

  methods: {
    ...mapActions("userInfo", {
      getUserPrintingOrders: GET_USER_PRINTING_ORDER_ACTION,
    }),

    // ...mapActions("userInfo", {
    //   getUserCommercialOrders: GET_USER_COMMERCIAL_ORDER_ACTION,
    // }),

    ...mapActions("userInfo", {
      getuserShippingAddress: GET_USER_SHIPPING_ADDRESS_ACTION,
    }),

    ...mapActions("userInfo", {
      updateuserShippingAddress: GETUPDATED_USER_SHIPPING_ADDRESS_ACTION,
    }),

    /****Get user locations*** */
    ...mapActions("location", {
      listCountries: GET_COUNTRY_ACTION,
    }),

    ...mapActions("location", {
      listStates: GET_STATE_ACTION,
    }),

    ...mapActions("location", {
      listCities: GET_CITY_ACTION,
    }),
    /****Get user locations*** */

    /****Unautjebtciation Action**** */

    ...mapActions("auth", {
      loggedOut: CLEAR_UP_LOGIN_ACTION,
    }),
    ...mapActions("auth", {
      loggedoutflag: CLEAR_UP_LOGIN_FLAG_ACTION,
    }),

    ...mapActions("auth", {
      userstoreEmpty: CLEAR_USER_STORE_ACTION,
    }),

    closeOtherOrderModal(id) {
      $(".modal-backdrop").remove();
      $("#" + id).removeClass("show");
      $("#" + id).attr("style", "");
      // $("#" + id).css("display", "none");
    },

    /****Unautjebtciation Action**** */
    accountname() {
      this.isActive = 'true'; 
      localStorage.setItem("isActive", this.isActive) 
    },

    address() {
      this.isActive = 'false'; 
      localStorage.setItem("isActive", this.isActive)   
    },

    // eslint-disable-next-line no-unused-vars
    addUserAddress(event) {
      let formData = new FormData();
      let first_name = $('input[name="first_name"]').val();
      let last_name = $('input[name="last_name"]').val();
      let title = $('input[name="title"]').val();
      let company = $('input[name="company"]').val();
      let address1 = $('input[name="address1"]').val();
      let address2 = $('input[name="address2"]').val();
      let country = $("#addcountry").val();
      let state = $("#add_state").val();
      let city = $("#add_city").val();
      let zip = $('input[name="zip"]').val();
      let day_phone = $('input[name="day_phone"]').val();
      let night_phone = $('input[name="night_phone"]').val();
      let email = $('input[name="email"]').val();

      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("title", title);
      formData.append("company", company);
      formData.append("address1", address1);
      formData.append("address2", address2);
      formData.append("country", country);
      formData.append("state_id", state);
      formData.append("city", city);
      formData.append("zip", zip);
      formData.append("day_phone", day_phone);
      formData.append("night_phone", night_phone);
      formData.append("email", email);
      if (this.user_token !== undefined) {
        let config = {
          headers: { Authorization: `Bearer ${this.user_token}` },
        };
        this.$axios
          .post(process.env.VUE_APP_BASE_URL + "address", formData, config)
          .then((res) => {
            if (res.status == 200) {
              toast.success("Address added successfully", { autoClose: 2400 });
               
              setTimeout(() => {
                location.reload();
              }, 2600);

              setTimeout(() => {
                console.log("now loaded");
              }, 2700);
            } else {
              toast.error(
                "Something went wrong,Please try latter",
                {
                  autoClose: 2400,
                },
                2700
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    editAddress(event) {
      let ship_id =
        event.target.getAttribute("data-shipid") !== "undefined"
          ? event.target.getAttribute("data-shipid")
          : "";

      let first_name =
        event.target.getAttribute("data-firstname") !== "undefined"
          ? event.target.getAttribute("data-firstname")
          : "";

      let last_name =
        event.target.getAttribute("data-lastname") !== "undefined"
          ? event.target.getAttribute("data-lastname")
          : "";
      let title =
        event.target.getAttribute("data-title") !== "undefined"
          ? event.target.getAttribute("data-title")
          : "";

      let email =
        event.target.getAttribute("data-email") !== "undefined"
          ? event.target.getAttribute("data-email")
          : "";

      let company =
        event.target.getAttribute("data-company") !== "undefined"
          ? event.target.getAttribute("data-company")
          : "";

      let dayphone =
        event.target.getAttribute("data-dayphone") !== "undefined"
          ? event.target.getAttribute("data-dayphone")
          : "";

      let nightphone =
        event.target.getAttribute("data-nightphone") !== "undefined"
          ? event.target.getAttribute("data-nightphone")
          : "";

      let address1 =
        event.target.getAttribute("data-address1") !== "undefined"
          ? event.target.getAttribute("data-address1")
          : "";

      let address2 =
        event.target.getAttribute("data-address2") !== "undefined"
          ? event.target.getAttribute("data-address2")
          : "";

      let country =
        event.target.getAttribute("data-country") !== "undefined"
          ? event.target.getAttribute("data-country")
          : "";

      let state =
        event.target.getAttribute("data-state") !== "undefined"
          ? event.target.getAttribute("data-state")
          : "";

      let city =
        event.target.getAttribute("data-city") !== "undefined"
          ? event.target.getAttribute("data-city")
          : "";

      let zip =
        event.target.getAttribute("data-zip") !== "undefined"
          ? event.target.getAttribute("data-zip")
          : "";

      this.userAddress.ship_id = ship_id;
      this.userAddress.first_name = first_name;
      this.userAddress.last_name = last_name;
      this.userAddress.title = title;
      this.userAddress.email = email;
      this.userAddress.company = company;
      this.userAddress.address1 = address1;
      this.userAddress.address2 = address2;
      this.userAddress.dayphone = dayphone;
      this.userAddress.nightphone = nightphone;
      this.userAddress.country = country;
      this.userAddress.state = state;
      this.userAddress.city = city;
      this.userAddress.zip = zip;

      let countries = this.listCountries();
      countries
        .then((res) => {
          if (res.status == 200) {
            // this.shiptoSubmitted.country = 231;
            this.formData.country = this.userAddress.country;
            this.countries = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let getcountry = { country_id: this.userAddress.country };
      this.listStates(getcountry)
        .then((resstate) => {
          if (resstate.status == 200) {
            this.formData.state = this.userAddress.state;
            this.states = resstate.data.data;
          }
        })
        .catch((cityerror) => {
          console.log(cityerror);
        });

      let getstates = {
        state_id: this.userAddress.state,
      };

      this.listCities(getstates)
        .then((citydata) => {
          if (citydata.status == 200) {
            this.formData.city = this.userAddress.city;
            this.cities = citydata.data.data;
          }
        })
        .catch((cityerror) => {
          console.log(cityerror);
        });

      // let getshipid = this.userAddress.ship_id;
      // eslint-disable-next-line no-unused-vars
    },

    updateUserAddress() {
      $("#addresssuccess").hide();
      $("#addresserror").hide();
      let formData = new FormData();
      let first_name = this.$refs.first_name;
      let last_name = this.$refs.last_name;
      let email = this.$refs.email;
      let title = this.$refs.title;
      let company = this.$refs.company;
      let address1 = this.$refs.address1;
      let address2 = this.$refs.address2;
      let country = this.$refs.country;
      let state = this.$refs.state;
      let city = this.$refs.city;
      let zip = this.$refs.zip;
      let day_phone = this.$refs.day_phone;
      let night_phone = this.$refs.night_phone;

      let getfname = first_name.getAttribute("name");
      let getlname = last_name.getAttribute("name");
      let getemail = email.getAttribute("name");
      let gettitle = title.getAttribute("name");
      let getcompany = company.getAttribute("name");
      let getaddress1 = address1.getAttribute("name");
      let getaddress2 = address2.getAttribute("name");
      let getcountry = country.getAttribute("name");
      let getstate = state.getAttribute("name");
      let getcity = city.getAttribute("name");
      let getzip = zip.getAttribute("name");
      let getdayphone = day_phone.getAttribute("name");
      let getnightphone = night_phone.getAttribute("name");

      formData.append(getfname, first_name.value);
      formData.append(getlname, last_name.value);
      formData.append(getemail, email.value);
      formData.append(gettitle, title.value);
      formData.append(getcompany, company.value);
      formData.append(getaddress1, address1.value);
      formData.append(getaddress2, address2.value);
      formData.append(getcountry, country.value);
      formData.append(getstate, state.value);
      formData.append(getcity, city.value);
      formData.append(getzip, zip.value);
      formData.append(getdayphone, day_phone.value);
      formData.append(getnightphone, night_phone.value);
      
      if (this.user_token) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.user_token}`,
        };
        let response = this.$axios.patch(
          `${process.env.VUE_APP_BASE_URL}address/${this.userAddress.ship_id}`,
          formData,
          {
            headers: headers,
          }
        );
        response
          .then((res) => {
            console.log(res);
            if (res.data[0].status == true) {
              this.updateuserShippingAddress(res.data[0].data);
              this.userAddressTable.draw();
              toast.success("Address updated successfully", {
                autoClose: 1400,
              });
        
              setTimeout(() => {
                document.getElementById("closebtn").click();
              }, 1500);
              setTimeout(() => {
                location.reload();
              }, 2600);

            }

            if (res.data.status == false) {
              let errors = res.data.error;
              let html = "";
              $("#addresserror").show();
              $.each(errors, function (e) {
                html = `<div class="mt-2 mb-2 alert alert-danger">${e} field is Required</div>`;
                $("#addresserror").append(html);
              });

              setTimeout(() => {
                $("#addresserror").hide();
                $("#addresserror").html("");
              }, 6400);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    deleteAddress(id) {
      console.log(id);
    },

    // eslint-disable-next-line no-unused-vars

    /*****************Edit Address *******************************/
    getCountry(event) {
      if (this.countries && this.formData.country) {
        let country_id = event.target.value;

        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "state", {
            params: { country_id: country_id },
          })
          .then((response) => {
            this.states = response.data.data;
            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getState(event) {
      if (this.states && this.formData.state) {
        let state_id = event.target.value; //this.submittedData.state;
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: state_id },
          })
          .then((response) => {
            this.cities = response.data.data;

            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getCity(event) {
      console.log(event);
    },
    /*****************Edit Address *******************************/

    /*****************Add Address ********************************/
    getaddCountry(event) {
      if (this.addcountries && this.AddformData.country) {
        let country_id = event.target.value;

        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "state", {
            params: { country_id: country_id },
          })
          .then((response) => {
            this.addstates = response.data.data;
            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getaddState(event) {
      if (this.addstates && this.AddformData.state) {
        let state_id = event.target.value; //this.submittedData.state;
        this.$axios
          .get(process.env.VUE_APP_BASE_URL + "city", {
            params: { state_id: state_id },
          })
          .then((response) => {
            this.addcities = response.data.data;

            // console.log(this.formData.state);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getaddCity(event) {
      console.log(event);
    },
    /*****************Add Address ********************************/
  },
   
  created() {  
    this.user_token =
      this.$store.state.auth.CurrentUserLogin.token !== undefined
        ? this.$store.state.auth.CurrentUserLogin.token
        : this.$store.state.auth.CurrentUserLogin.CurrentUserLogin.token;
    let axios = this.$axios;

    //  Commercial Orders Table render from here Start
    // this.getUserCommercialOrders(this.user_token)
    //   .then((res) => {
    //     if (res) {
    //       let order_status = {
    //           0 :"Unpaid / order not sent",
    //           1 :'Cash/Check Orders',
    //           2 :'Paid',
    //           3 :'Sent to Processing',
    //           4 :'Proccessed and Shipped',
    //           5 :'Cancelled',
    //       }
    //       console.log(order_status, 'order_status')
          
    //       console.log(res, "getUserCommercialOrders");
    //       if (res.success == true) {
    //         if ($.fn.dataTable.isDataTable("#commercialorders")) {
    //           $("#commercialorders").DataTable({
    //             paging: false,
    //           });
    //         } else {
    //           $("#commercialorders").DataTable({
    //             data: res.data,
    //             pageLength: 5,
    //             lengthMenu: [
    //               [5, 10, 20, -1],
    //               [5, 10, 20, 30, 40],
    //             ],

    //             columns: [
    //               { data: "id" },
    //               {
    //                 data: "created_at", 
    //                 render: function (data) {
    //                   let dateObj = new Date(data);
    //                   let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    //                   let day = dateObj.getUTCDate();
    //                   let year = dateObj.getUTCFullYear();
    //                   let newdate = year + "-" + month + "-" + day;
    //                   return newdate;
    //                 },
    //               },
    //               {
    //                 data: "payment_type",
    //               },
    //               {
    //                 data: "Action",
    //                 render: function (data) {
    //                     let html = '<div class="d-flex justify-content-center"><a data-orderId="' +data.order_id +'" class="btn btn-primary btn-sm align-self-center viewCommercialOrdersDetails"><span class="glyphicon glyphicon"></span>View</a></div>';
    //                     return html;
    //                 }
    //               }
    //             ],
    //             drawCallback: function () {
    //               let viewCommercialOrdersDetails = document.querySelectorAll( ".viewCommercialOrdersDetails");
    //               viewCommercialOrdersDetails.forEach(function (e) {
    //                 e.addEventListener("click", function (event) {
    //                   event.preventDefault();
    //                   let orderId = event.target.getAttribute("data-orderId");
    //                   window.location.assign("/#/account/view/" + orderId + "");
    //                   location.reload();
    //                 });
    //               });
    //             },
    //             order: [[0, "desc"]],
    //           });
    //         }
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    //  Commercial Orders Table render from here End

    this.getuserShippingAddress(this.user_token)
      .then((res) => {
        if (res) {
          if (res.status == 200) {
            console.log(res.data.data, "res.data.data")
            //Printing Orders
            if ($.fn.dataTable.isDataTable("#alladdress")) {
              let alladdress = $("#alladdress").DataTable();
              alladdress.destroy();
              this.userAddressTable = $("#alladdress").DataTable({
                paging: false,
              });
            } else {
              let alladdress = $("#alladdress").DataTable();
              let _that = this;
              alladdress.destroy();
              this.$nextTick(function () {
                this.userAddressTable = $("#alladdress").DataTable({
                  data: res.data.data,
                  pageLength: 10,
                  lengthMenu: [
                    [5, 10, 20, -1],
                    [5, 10, 20, 30, 40],
                  ],

                  columns: [
                    { data: "id",visible: false, },
                    { data: "full_name" },
                    { data: "full_address" },
                    {
                      data: "Action",
                      render: function (val) {
                        let html =
                          '<div class="d-flex justify-content-center"><a id="editaddress" data-shipid="' +
                          val.shipping_id +
                          '"  data-firstname ="' +
                          val.first_name +
                          '" data-lastname="' +
                          val.last_name +
                          '" data-title="' +
                          val.title +
                          '" data-email="' +
                          val.email +
                          '" data-company="' +
                          val.company +
                          '"  data-dayphone="' +
                          val.day_phone +
                          '" data-nightphone="' +
                          val.night_phone +
                          '"  data-address1="' +
                          val.address1 +
                          '" data-address2="' +
                          val.address2 +
                          '"  data-city="' +
                          val.city +
                          '"  data-state="' +
                          val.state_id +
                          '" data-country="' +
                          val.country +
                          '" data-zip="' +
                          val.zip +
                          '" data-bs-toggle="modal" data-bs-target="#edit-address" class="btn btn-primary btn-sm"><span class="glyphicon glyphicon"></span>Edit</a>';

                        html +=
                          '&nbsp;<a id="deleteaddress"  data-shipid="' +
                          val.shipping_id +
                          '" class="deleteaddress btn btn-danger btn-sm">Delete</a></div>';

                        return html;
                      },
                    },
                  ],

                  drawCallback: function () {
                    let getdeleteSelector =
                      document.querySelectorAll(".deleteaddress");
                    getdeleteSelector.forEach(function (e) {
                      e.addEventListener("click", function (event) {
                        event.preventDefault();
                        let shipid = event.target.getAttribute("data-shipid");
                        let confirmbox = confirm(
                          "Are you sure you want to delete address?"
                        );
                        if (confirmbox && confirmbox == true) {
                          if (_that.user_token !== "") {
                            const headers = {
                              Authorization: `Bearer ${_that.user_token}`,
                            };
                            let response = axios.get(
                              `${process.env.VUE_APP_BASE_URL}deleteShippingAddress/${shipid}`,
                              { headers }
                            );
                            response
                              .then((res) => {
                                if (res.data.status == 1) {
                                  toast.success(
                                    "Address removed successfully",
                                    {
                                      autoClose: 2600,
                                    }
                                  );
                                  setTimeout(() => {
                                    location.reload();
                                  }, 2650);
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }
                        }
                      });
                    });
                  },

                  order: [[0, "desc"]],
                });
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error getuserShippingAddress");
      });
  },

  updated() {
    console.log(localStorage.getItem("isAccountPageReload") , " out if isAccountPageReload")
    if (localStorage.getItem("isAccountPageReload") == 'true') {
        console.log(localStorage.getItem("isAccountPageReload") , "in if isAccountPageReload")
        location.reload()
        localStorage.setItem("isAccountPageReload", "false");
    }
    window.onhashchange = function () { location.reload() };
    let axios = this.$axios;
    // Other Order table render from here Start
    let otherorders = $.fn.dataTable.isDataTable("#otherorders");
    if (!otherorders) {
      console.log(otherorders, "otherorders");
      $("#otherorders").DataTable({
        destroy: true,
        pageLength: 5,
        lengthMenu: [
          [5, 10, 20, -1],
          [5, 10, 20, 30, 40],
        ],
        paging: true,
        lengthChange: true,
        searching: true,
        ordering: true,
        info: true,
        autoWidth: false,
        serverSide: true,
        ajax: {
          url: `${process.env.VUE_APP_BASE_URL}getTMOrdersAll?page=0&limit=100`,
          dataType: "json",
          type: "POST",
          headers: { Authorization: `Bearer ${this.user_token}` },
          error: function (error) {
            console.log(error, "error other orders");
          },
        },

        columns: [
          { data: "id" },
          { data: "order_date" },
          { data: "status" },
          { data: "Action" },
        ],
      });

      // Other order details modal open start
      setTimeout(() => {
        let _that = this;
        let tmorderViewModal = document.querySelectorAll(".tmorderViewModal");
        console.log(tmorderViewModal, "tmorderViewModal");
        tmorderViewModal.forEach(function (e) {
          e.addEventListener("click", function (event) {
            event.preventDefault();
            let orderid = event.target.getAttribute("data-orderid");
            if (_that.user_token) {
              const headers = { Authorization: `Bearer ${_that.user_token}` };
              let response = axios.get(
                `${process.env.VUE_APP_BASE_URL}getSingleTMOrdersByOrderID/${orderid}`,
                { headers }
              );
              response
                .then((res) => {
                  console.log(res.data.data, "res");
                  $('<div class="modal-backdrop"></div>').appendTo(
                    document.body
                  );
                  $("#OtherOrderModal").addClass("show");
                  $("#OtherOrderModal").css("display", "block");

                  $("#order-view_id").text(res?.data?.data?.id);
                  $("#order_name").text(res?.data?.data?.full_name);
                  $("#order_companyname").text(res?.data?.data?.company);

                  $("#order_address1").text(res?.data?.data?.address_1);
                  $("#order_address2").text(res?.data?.data?.address_2);

                  $("#order_city").text(res?.data?.data?.city);
                  $("#order_state").text(res?.data?.data?.state);

                  $("#order_zip").text(res?.data?.data?.zip);
                  $("#order_phone").text(res?.data?.data?.phone);

                  $("#order_email").text(res?.data?.data?.email);
                  $("#order_template").text(res?.data?.data?.tm_template_id);

                  $("#order_price").text(res?.data?.data?.price);
                  $("#order_pricetotal").text(res?.data?.data?.price);
                })
                .catch((error) => {
                  throw new Error(error);
                });
            }
          });
        });
      }, 4000);
      // Other order details modal open start
    }
    // Other Order table render from here End

     // Other Order table render from here Start
    let commercialorders = $.fn.dataTable.isDataTable("#commercialorders");
    if (!commercialorders) {
        $("#commercialorders").DataTable({
            destroy: true,
            pageLength: 5,
            lengthMenu: [
                [5, 10, 20, -1],
                [5, 10, 20, 30, 40],
            ],
            paging: true,
            lengthChange: true,
            searching: true,
            ordering: true,
            info: true,
            autoWidth: false,
            serverSide: true,
            ajax: {
                url: `${process.env.VUE_APP_BASE_URL}all_commercial_orders?page=0&limit=100`,
                dataType: "json",
                type: "POST",
                headers: { Authorization: `Bearer ${this.user_token}` },
                error: function (error) {
                    console.log(error, "error other orders");
                },
            },

            columns: [
                { data: "id" },
                { data: "order_date" },
                { data: "status" },
                { data: "Action" },
            ],
        });

        // Other order details modal open start
        setTimeout(() => {
            let _that = this;
            let commercial_view_order = document.querySelectorAll(".commercial_view_order");
            console.log(commercial_view_order, "commercial_view_order");
            commercial_view_order.forEach(function (e) {
            e.addEventListener("click", function (event) {
                event.preventDefault();
                let orderid = event.target.getAttribute("data-orderid");
                if (_that.user_token) {
                const headers = { Authorization: `Bearer ${_that.user_token}` };
                let response = axios.get(
                    `${process.env.VUE_APP_BASE_URL}commercial_view_order/${orderid}`,
                    { headers }
                );
                response
                    .then((res) => {
                        console.log(res.data.data, "res");
                        $('<div class="modal-backdrop"></div>').appendTo(document.body);
                        $("#CommercialOrdersModal").addClass("show");
                        $("#CommercialOrdersModal").css("display", "block");

                        $("#orderViewId").text(res?.data?.data?.id);

                        let UpdatedateObj = new Date(res?.data?.data?.updated_at);
                        let Updatemonth = ("0" + (UpdatedateObj.getMonth() + 1)).slice(-2);
                        let Updateday = UpdatedateObj.getUTCDate();
                        let UpdateYear = UpdatedateObj.getUTCFullYear();
                        let UpdateHour = UpdatedateObj.getUTCHours();
                        let UpdateMinutes = UpdatedateObj.getUTCMinutes();
                        let updated_at = UpdateYear + "-" + Updatemonth + "-" + Updateday+" "+ UpdateHour+":"+UpdateMinutes;

                        let CreatedateObj = new Date(res?.data?.data?.created_at);
                        let createmonth = ("0" + (CreatedateObj.getMonth() + 1)).slice(-2);
                        let createday = CreatedateObj.getUTCDate();
                        let createYear = CreatedateObj.getUTCFullYear();
                        let createHour = CreatedateObj.getUTCHours();
                        let createMinutes = CreatedateObj.getUTCMinutes();
                        let create_at = createYear + "-" + createmonth + "-" + createday+" "+ createHour+":"+createMinutes;

                        $("#lastChangeDateTime").text(updated_at);
                        $("#orderDateTime").text(create_at);

                        $("#IpAddress").text(res?.data?.data?.cust_ip);
                        $("#customerName").text(res?.data?.data?.name);
                        $("#customerEmail").text(res?.data?.data?.email);
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
                }
            });
            });
        }, 4000);
        // Other order details modal open start
    }
    // Other Order table render from here End

    // Printing Orders Table render from here Start
    let printingorders = $.fn.dataTable.isDataTable("#printingorders");
    if (!printingorders) {
      this.getUserPrintingOrders(this.user_token)
        .then((res) => {
          console.log(res, "getUserPrintingOrders");
          if (res) {
            if (res.count && res.count > 0) {
              this.Orders = res.data;
              $("#printingorders").DataTable({
                destroy: true,
                stateSave: true,
                data: res.data,
                pageLength: 5,
                lengthMenu: [
                  [5, 10, 20, -1],
                  [5, 10, 20, 30, 40],
                ],
                columns: [
                  { data: "id" },
                  {
                    data: "created_at",
                    render: function (data) {
                      let dateObj = new Date(data);
                      let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
                      let day = dateObj.getUTCDate();
                      let year = dateObj.getUTCFullYear();
                      let newdate = year + "-" + month + "-" + day;
                      return newdate;
                    },
                  },
                  { data: "payment_type" },
                  {
                    data: "Action",
                    render: function (data) {
                      let html =
                        data.files_data == null
                          ? '<div class="d-flex justify-content-center"><a style="color:#FFF;width:130px" href="/#/account/upload_artwork/' +
                            data.order_id +
                            '"  class="btn btn-info btn-sm"><span class="glyphicon glyphicon"></span>Uploads Artworks <a data-orderId="' +
                            data.order_id +
                            '" class="ms-2 btn btn-primary btn-sm align-self-center viewPrintOrderDetails"><span class="glyphicon glyphicon"></span>View</a>'
                          : '<div class="d-flex justify-content-center"><a style="color:#FFF"  data-orderId="' +
                            data.order_id +
                            '" class="btn btn-primary btn-sm viewPrintOrderDetails"><span class="glyphicon glyphicon"></span>View</a></div></div>';
                      return html;
                    },
                  },
                ],
                drawCallback: function () {
                  let viewPrintOrderDetails = document.querySelectorAll( ".viewPrintOrderDetails"
                  );
                  viewPrintOrderDetails.forEach(function (e) {
                    e.addEventListener("click", function (event) {
                      event.preventDefault();
                      let orderId = event.target.getAttribute("data-orderId");
                      window.location.assign("/#/account/view/" + orderId + "");
                      location.reload();
                    });
                  });
                },
                order: [[0, "desc"]],
              });
            } else {
              console.log("Hello Main");
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    }
    // Printing Orders Table render from here End

    this.getuserShippingAddress(this.user_token)
      .then((res) => {
        console.log(res, "getuserShippingAddress");
      }).catch((error) => {
        console.log(error, "Error from getuserShippingAddress");
    });

  },

};
</script>

<style scoped>
.container {
  background: #edf1f385;
  padding: 40px;
  border: 1px solid #80808036;
}

#persondetails {
  background: #fff;
  box-shadow: 1px 1px 2px 2px #38a7bb38;
  width: 100%;
  max-width: 100%;
}

.activetab {
  color: #ffffff;
  border-top: 1px solid #38a7bb;
  border-bottom: 1px solid #38a7bb;
  border-left: 1px solid #38a7bb;
  border-right: 1px solid #38a7bb;
  background: #38a7bb;
}

#orders {
  margin-left: -46px;
  background: #fff !important;
  padding: 40px !important;
}

h2 {
  font-weight: 600;
  /* color: #9e9e9e8a; */
  text-shadow: 1px 1px 1px #80808096;
}

#alltables h2 {
  color: #9e9e9e8a;
  text-shadow: 1px 1px 1px grey;
}

.noshow {
  display: none;
}

#alladdress {
  background: #fff;
  width: 100% !important;
}

#alladdress .dataTables_length {
  float: left !important;
}

ul.nav.nav-pills > li:nth-child(3) a {
  padding: 8px 8px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 0.8rem;
}

#add-address .modal-content {
  width: 900px;
  right: 200px;
}

.btn-primary {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

.btn-primary:hover {
  border-radius: 0px 15px 0px 15px !important;
}

.btn-sm {
  color: #fff !important;
  padding: 4px 11px !important;
  font-size: 10px !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}

.btn:hover {
  border-radius: 0px 15px 0px 15px !important;
}

button.close {
  font-size: 1.6em !important;
  background: transparent !important;
  border: unset !important;
}
</style>
