import {
  SET_CART_DATA_MUTATION,
  SET_UPDATE_CART_DATA_MUTATION, 
  SET_CART_QUANTITY_MUTATION,
  SET_DELETE_SINGLE_ITEM_CART_MUTATION,
  SET_DELETE_ALL_ITEMS_CART_MUTATION,
  HELCIUM_PAYMENT_MUTATION,
  CHECK_PAYMENT_MUTATION,
  CLEAR_CART_MUTATION,
  PAYPAL_PAYMENT_MUTATION,
  SET_CART_REVIEW_MUTATION,
  DELETE_UPLOADED_CART_ARTWORK_MUTATION,
  DELETE_SINGLE_UPLOADED_CART_ARTWORK_MUTATION,
  SET_UPDATE_CART_SHIPFROM_DATA_MUTATION
} from "@/store/storeconstants";

export default {
  [SET_CART_DATA_MUTATION](state, payload) {
    //let getData=[]
    // console.log(state.cart.data)
    //console.log((payload.data.data.product_data))
    

    state.cart.push(payload.data.data);
  },
  //SET_UPDATE_CART_SHIPFROM_DATA_MUTATION

  [SET_UPDATE_CART_DATA_MUTATION](state, payload) {
    //let getData=[]
    // console.log(state.cart.data)
    //console.log((payload.data.data.product_data))
    let order_id="";
   order_id=payload.data.data.id;
  
   let storedState=state.cart;
   storedState.map(function(e){
    if(parseInt(e.id)===parseInt(order_id))
    {
      //cart_data
      console.log('payload.data.data????',payload.data.data)
      e.file_details=payload.data.data
      e.files_data=payload.data.files_data
    }
   })

 

    //state.cart.file_details.push(payload.data.data);
  },

  [SET_UPDATE_CART_SHIPFROM_DATA_MUTATION](state, payload) {
    //let getData=[]
    // console.log(state.cart.data)
    //console.log((payload.data.data.product_data))
  //   let order_id="";
  //  order_id=payload.data.data.id;
  let items =payload.data.data.items;

   console.log('items..',items); 
   
   let storedState=state.cart;
   storedState.map(function(e,index){ 
     
    if(parseInt(e.id)===parseInt(items.items[index].id))
    {
      //cart_data 
        console.log('If.....');
       e.shipping_quote =items.items[index].shipping_quote;
      // e.files_data=payload.data.files_data
    }
   })

 

    //state.cart.file_details.push(payload.data.data);
  },
  
  [SET_CART_QUANTITY_MUTATION](state, payload) {
    state.updatedCart = payload.data;
  },

  [SET_DELETE_SINGLE_ITEM_CART_MUTATION](state, payload) {
    state.deletedSingleItem = payload.data;
  },

  [SET_DELETE_ALL_ITEMS_CART_MUTATION](state, payload) {
    state.deleteAllItems = payload.data;
  },

  [ HELCIUM_PAYMENT_MUTATION ](state, payload) {
    state.HelciumPayment = payload.data;
  },

  [ CHECK_PAYMENT_MUTATION ](state, payload) {
    state.CheckPayment = payload.data;
  },

  [ PAYPAL_PAYMENT_MUTATION ](state, payload) {
    state.paypalPayment = payload.data;
  },


  /***CLEAR CART DATA *****/
  [ CLEAR_CART_MUTATION ](state) {
    state.cart= [];
  },
  
  /***CLEAR CART DATA *****/

  [SET_CART_REVIEW_MUTATION ](state,payload){
    state.reviewPayment= payload.data;
  },

  /****Delete Artwork data from cart *****/
  [ DELETE_UPLOADED_CART_ARTWORK_MUTATION](state,payload){
    let artworkfile=payload
    let getcarts=state.cart
   console.log(artworkfile);
    getcarts.forEach((e)=>{
   
    //  if(e.artwork_files.name===artworkfile)
    //  {
      //remove artwork files from cart data if file name matches//
      e.artwork_files={}
    //  }
    //  else
    //  {
    //   console.log("file are not equal")
    //  }
    })
  
   
  },

   [ DELETE_SINGLE_UPLOADED_CART_ARTWORK_MUTATION](state,payload){
        let storedState= state.cart; // state.cart;
        console.log('storedState...??',payload);
         let order_id="";
        order_id=payload.data.data.id;

            console.log('order_id??',order_id);
        storedState.map(function(e){
            if(parseInt(e.id)===parseInt(order_id))
            {
            e.file_details=''
            }
        }) 

  },
 
  /***Delete Artwork data fro cart *****/

 
};
