<template>
  <div :style="bg_image" class="container_fluid" id="side_shrink">
    <div id="content">
      <div class="top-content">
        <div class="inner-bg">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-sm-offset-2 text text-center mb-5">
                <h1 class="text-center text-white" id="registertitle">
                  <strong>Forgot Password</strong>
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2"></div>
              <div
                class="col-sm-8 col-sm-push-2 form-box animated fadeInDown"
                data-animate="fadeInDown"
                style="opacity: 0"
              >
                <form @submit.prevent="submitForm()" method="post">
                  <fieldset>
                    <div class="form-top">
                      <div class="form-top-left">
                        <h3>Forgot Password</h3>
                      </div>
                      <div class="form-top-right">
                        <i class="fa fa-user"></i>
                      </div>
                    </div>
                    <div class="form-bottom">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              class="form-control"
                              placeholder="E-Mail Address"
                              type="email"
                              autofocus
                              required
                              v-model.trim="formData.email"
                            />
                            <span
                              class="d-block font-11 ms-1 mt-1 text-danger text-start"
                              v-if="errors.email"
                            >
                              {{ errors.email }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <button
                            type="submit"
                            class="register btn btn-lg btn-info pull-right"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div class="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container -->
    </div>
  </div>
</template>

<script>
import ForgotPasswordValidations from "@/services/ForgotPasswordValidations";
import axios from "axios";
//import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "ForgotPassword",
  data() {
    return {
      formData: {
        email: "",
      },
      errors: [],
      ServerSideError: "",
      bg_image: {
        backgroundImage: "url(../img/bg.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
    };
  },
  methods: {
    submitForm() {
      console.log("this.formData.email..", this.formData.email);
      let validations = new ForgotPasswordValidations(this.formData.email);

      this.errors = validations.checkValidations();
      if ("email" in this.errors) {
        return false;
      }

      let formData = new FormData();

      formData.append("email", this.formData.email);

      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "password/email/?email=" +
            this.formData.email +
            "&type=web"
        )
        .then((res) => {
          console.log("res..", res);

          if (res.data.success == false) {
            let errorArray = res.data.message;
            if (errorArray.email) {
              toast.error("Sorry this email already been taken", {
                autoClose: 2500,
              });
            }
          }

          if (res.data.status == true) {
            toast.success("We have e-mailed your password reset link!", {
              autoClose: 2500,
            });
            // Reset the form fields

            this.formData.email = "";
            // console.log("hi.... jk");
            //  this.$router.push("/verify-otp");

            //setTimeout(() => {

            //}, 2800);
          }
        });
      // Send a request to your server to handle the password reset email
      // This should include the user's email address
      // After a successful response, you can show a success message to the user
      // and possibly redirect them to a confirmation page.
    },
  },

  created() {
    if (document.getElementById("getbtnclose")) {
      document.getElementById("getbtnclose").click();
    }
  },
};
</script>

<style scoped>
.btn-info {
  color: #fff !important;
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
}

.btn:hover {
  border-radius: 0px 15px 0px 15px !important;
}
</style>
