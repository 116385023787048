<template>
  <div
    class="modal fade"
    id="login-choser"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    {{ console.log(storeData, "storeData from LoginChooser File") }}
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            id="loginchooserclose"
            aria-hidden="true"
          >
            ×
          </button>
          <h4 class="modal-title" id="Login">Store Chooser</h4>
        </div>

        <div class="modal-body">
          <h4>
            Hello,
            {{
              storeData?.data?.data?.first_name +
              " " +
              storeData?.data?.data?.last_name
            }}
          </h4>
          <div class="row">
            <div
              class="col-md-4"
              v-for="(stores, index) in storeData?.data?.role_scopes"
              v-bind:key="index"
            >
              <div v-if="stores == 'admin'">
                <form
                  :action="
                    baseWebUrl + 'users/tlogin/' + storeData?.data?.data?.tlogin
                  "
                  method="get"
                >
                  <div>
                    <button
                      type="submit"
                      class="btn btn-template-main btn-block"
                      id="login_form"
                    >
                      <i class="fa fa-sign-in"></i> {{ stores }}
                    </button>
                  </div>
                </form>
              </div>
              <div v-else-if="stores == 'store' || stores == 'hsexposed'">
                <form
                  @submit.prevent="gotoCartorAccount(baseURL, checkCart)"
                  :action="
                    checkCart.length > 0
                      ? baseURL + 'cart'
                      : baseURL + 'account'
                  "
                  method="get"
                >
                  <div>
                    <button
                      type="submit"
                      class="btn btn-template-main btn-block"
                      id="login_form"
                    >
                      <i class="fa fa-sign-in"></i> {{ stores }}
                    </button>
                  </div>
                </form>
              </div>
              <div v-else>
                <form
                  @submit.prevent="gotoCartorAccount(baseURL, checkCart)"
                  :action="
                    checkCart.length > 0
                      ? baseURL + 'cart'
                      : baseURL + 'account'
                  "
                  method="get"
                >
                  <div>
                    <button
                      type="submit"
                      class="btn btn-template-main btn-block"
                      id="login_form"
                    >
                      <i class="fa fa-sign-in"></i> {{ stores }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { SET_USER_LOGIN_DATA_MUTATION } from '@/store/storeconstants';
import { useStore, mapActions } from "vuex";
import { toast } from "vue3-toastify";

import { LOGIN_TLOGIN_ACTION } from "../store/storeconstants";
// import { mapMutations } from 'vuex';
export default {
  name: "LoginChooser",
  props: {
    storeData: Object,
  },
  setup() {
    const store = useStore();
    const checkCart = store.state.cartData.cart;
    const baseURL = process.env.VUE_APP_BASE_URL_LOCAL;
    const baseWebUrl = process.env.VUE_APP_BASE_URL_WEB;
    const admincmsURL = process.env.VUE_APP_ADMIN_CMS_WEB;
    const accnturl = baseURL + "/account";

    return {
      store,
      accnturl,
      baseURL,
      admincmsURL,
      baseWebUrl,
      checkCart,
    };
  },
  mounted() {
    //console.log(this.baseURL);
  },
  methods: {
    goToPage(page, logininfo) {
      switch (page) {
        case "store":
          document.getElementById("loginchooserclose").click();
          //check if cart if empty

          if (
            this.store.state.cartData.cart !== undefined &&
            this.store.state.cartData.cart.length > 0
          ) {
            this.$router.push("/cart");
          } else {
            toast.success("Logged in successfully", { autoClose: 800 });
            setTimeout(() => {
              this.$router.push("/account");
            }, 600);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
          }
          //check if cart is empty
          //this.$router.push('/cart')

          break;
        case "crew":
          console.log(page, logininfo);
          break;
        case "talent":
          console.log(page, logininfo);
          break;
        case "admin":
          console.log(page, logininfo);
          break;
        case "pitch":
          console.log(page, logininfo);
          break;
      }
    },
    ...mapActions("auth", {
      tloginchooser: LOGIN_TLOGIN_ACTION,
    }),
    handleClick() {
      // This function will be called when the checkbox state changes

      // Checkbox is checked
      console.log("Checkbox is checked");
      // Call your desired function here
      this.tloginchooser();
    },
    gotoCartorAccount(baseurl, cartitems) {
      console.log(cartitems, cartitems.length, "cartitems");
      if (cartitems.length > 0) {
        toast.success("Logged in successfully Here"), { autoClose: 2400 };
        document.getElementById("loginchooserclose").click();
        this.$router.push("/cart");
      } else {
        toast.success("Logged in successfully"), { autoClose: 2400 };
        document.getElementById("loginchooserclose").click();
        this.$router.push("/account");
        setTimeout(() => {
              window.location.reload();
        }, 2000);
         
      }
    },

    // eslint-disable-next-line no-unused-vars
    submitUserForm(event, baseURL, checkCart) {
      if (document.getElementById("loginchooserclose")) {
        document.getElementById("loginchooserclose").click();
      }
      toast.success("Logged in successfully", { autoClose: 2400 });

      setTimeout(() => {
        this.$router.push("/account");
      }, 2800);
      setTimeout(() => {
            window.location.reload();
      }, 3000);
    },
  },
};
</script>

<style scoped>
h4 {
  font-family: "Lora", serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.1;
  color: #333333;
}

.modal-header .close {
  margin-top: -2px;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.close {
  position: absolute;
  right: 20px;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  font-size: 1.2rem !important;
  background: transparent !important;
}

.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

@media (min-width: 400px) {
  #login-choser form {
    display: inline-block;
  }
}

#login-choser form {
  margin: 25px 0px;
}

@media (min-width: 400px) {
  #login-choser form > div {
    padding-right: 10px;
  }
}

#login-choser form > div .btn {
  text-align: center;
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  background: #38a7bb !important;
  border: solid 15px #ffffff !important;
  position: relative;
  padding: 0px 0px 0px 0px;
  letter-spacing: 0px;
  font-size: 11px !important;
  color: #fff !important;
}

#login-choser form > div .btn:hover {
  border: solid 0px #f9f8f8;
  border-radius: 50% 10%;
}
</style>
