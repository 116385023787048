import { createRouter, createWebHashHistory } from 'vue-router'


import HomeView from '../views/HomeView.vue';
import LoginPage from '@/components/LoginPage';
import Register from '../views/UserRegister.vue';
import CmsPages from '../views/pages/CmsPages.vue';
import PrintingPage from '../views/pages/PrintingPage.vue';
import PageNotFound from "../components/PageNotFound.vue";
import CartView from "../views/CartView.vue";
import ShippingView from "../views/ShippingView.vue";
import PaymentView from "../views/PaymentView.vue";
import ReviewDetails from "../views/ReviewDetails.vue";
//import SinglePrint from "../views/pages/SinglePrint.vue";
import MyAccountView from "../views/Users/MyAccount.vue";
import HelciumPayment from "@/components/Payments/HelciumPayment.vue"
import CheckPayment from "@/components/Payments/CheckPayment.vue"
import PaypalPayment from "@/components/Payments/PaypalPayment.vue"
import UploadArtwork from "../views/orders/uploadArtwork.vue"
import OrderList from "../views/orders/OrderList.vue";
import ForgotPassword from "@/components/ForgotPassword.vue"; 

import PasswordReset from "@/components/PasswordReset.vue";
import { isAuthenticated } from './middleware';
//import $ from 'jquery';ForgotPassword

//import { SET_USER_LOGIN_DATA_MUTATION } from "@/store/storeconstants";

//import { useStore } from "vuex";



// eslint-disable-next-line no-unused-vars
//import store from "@/store/store" //users/tlogin

const routes = [

    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { auth: true },
    },

    {
        path: '/register',
        name: 'register',
        component: Register,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/account',
        name: 'Account',
        component: MyAccountView,
         beforeEnter: [
            isAuthenticated
        ]
    },

    {
        path: '/pages/:pagename',
        name: 'pages',
        component:CmsPages,
    },

    // {
    //     path: '/page/printing',
    //     name: 'page',
    //     component: PrintingPage,
    // },
        
    {
        path: '/product/:pagename',
        name: 'product',
        component:PrintingPage,
        
    },
    
    {
        path: '/cart',
        name: 'cart',
        component: CartView,
        // beforeEnter: [
        //     isAuthenticated
        // ]
    },
    
    {
        path: '/shipping',
        name: 'shipping',
        component: ShippingView, 
        meta: { auth: true },
        beforeEnter: [
            isAuthenticated
        ]
    },
    
    {
        path: '/payment',
        name: 'payment',
        component: PaymentView, 
        beforeEnter: [
            isAuthenticated
        ]
    },
    {
       path :'/review',
       name:'review',
       component:ReviewDetails,
       beforeEnter: [
        isAuthenticated
    ]
    },
    /***Payment Methods ***/

    /***order view and upload artwork page ***/
    {
       path:'/account/upload_artwork/:id', 
       name:'uploadartwork',
       component:UploadArtwork
    },
    {
        path:'/account/view/:id', 
        name:'orderlist',
        component:OrderList,
        beforeEnter: [
            isAuthenticated
        ]
        //cart/shipping_calculator
    },
     {
        path:'/cart/shipping_calculator', 
        name:'shipping_calculator',
       // component:OrderList,
        beforeEnter: [
            isAuthenticated
        ] 
    },

    /***order view and upload artwork page   ***/
    {
        path:'/payment/helcim/checkout',
        name: 'helcim-checkout',
        component: HelciumPayment,
        beforeEnter: [
            isAuthenticated
        ]
    },
    {
        path:'/payment/check-cash',
        name:'check-cash',
        component:CheckPayment,
        beforeEnter: [
            isAuthenticated
        ]
    },
    {
        path:'/payment/paypal',
        name:'paypal-payment',
        component:PaypalPayment,
        beforeEnter: [
            isAuthenticated
        ]
       
    },//
    /***Payment Methods ***/
    
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
    },
    {
        path: '/404/',
        name: 'PageNotFound',
        component: PageNotFound,
    },
    {
        path: '/forgot-password',
        name: 'forgotpassword',
        component:ForgotPassword,
    }, 
    {
        path: '/password-reset/:link',
        name: 'password-reset',
        // eslint-disable-next-line no-undef
        component:PasswordReset,
    },
    {
        path: '/verify-otp',
        name: 'verify-otp',
        // eslint-disable-next-line no-undef
        //component:VerifyOtp,
    },
    

]

const router = createRouter({
    history: createWebHashHistory(),
    mode: 'history',
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
})




// eslint-disable-next-line no-unused-vars
router.beforeEach((to,from,next=false) => {
    // Scroll to the top of the page
    // const store = useStore();
    // console.log(this);
    // console.log('store..',store);
    // this.emitter.emit("closeMenu");

  //console.log('to..',to,from,next);

    
    
    //console.log(to.params.pagename)
})

export default router
