import {
    SET_COUNTRY_MUTATION,
    SET_STATE_MUTATION,
    SET_CITY_MUTATION  
  } from "@/store/storeconstants";
  
  export default {
    [SET_COUNTRY_MUTATION](state, payload) { 
  
      state.country=payload
    },
   [SET_STATE_MUTATION](state, payload) { 

    state.states=payload
   },

   [SET_CITY_MUTATION ](state,payload){
    state.city=payload
   }
  
  };
  