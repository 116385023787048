import SignupValidations from "@/services/SignupValidations";
import {
    GET_COUNTRY_ACTION,
    SET_COUNTRY_MUTATION,
    GET_STATE_ACTION,
    SET_STATE_MUTATION,
    GET_CITY_ACTION,
    SET_CITY_MUTATION

} from "@/store/storeconstants";
const axios = require("axios");


export default {

    async [GET_COUNTRY_ACTION](context) {
        try {

            let response = await axios.get(
                `${process.env.VUE_APP_BASE_URL}country`,
            );
            if (response.status === 200) {
                context.commit(SET_COUNTRY_MUTATION, response);
                return response
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response
            );
            throw errormsg;
        }
    },

    async [GET_STATE_ACTION](context, payload) {
        try {
            let country_id = payload.country_id
            let response = await axios.get(
                `${process.env.VUE_APP_BASE_URL}state`, { params: { country_id: country_id } }
            );
            if (response.status === 200) {
                context.commit(SET_STATE_MUTATION, response);
                return response
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response
            );
            throw errormsg;
        }
    },

    async [GET_CITY_ACTION](context, payload) {
        try {
            //let country_id=payload.country_id
            let state_id = payload.state_id
            let response = await axios.get(
                `${process.env.VUE_APP_BASE_URL}city`, { params: { state_id: state_id } }
            );
            if (response.status === 200) {
                context.commit(SET_CITY_MUTATION, response);
                return response
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(
                //error.response.data
                error.response
            );
            throw errormsg;
        }
    },

}