import SignupValidations from "@/services/SignupValidations";
import {
    GET_ATTRIBUTES_DATA_ACTION, SET_ATTRIBUTES_DATA_MUTATION,GET_PRODUCT_ACTION, GET_SINGLE_PRODUCT_ACTION, SET_PRODUCT_MUTATION, SET_SINGLE_PRODUCT_MUTATION, GET_ARTWORK_DATA_ACTION, SET_ARTWORK_DATA_MUTATION
    ,GET_ARTWORK_UPLOAD_ACTION,SET_ARTWORK_UPLOAD_MUTATION,
    GET_FRAMEWORK_ACTION,SET_FRAMEWORK_MUTATION,SAVE_ARTWORK_ACTION,
    SAVE_ARTWORK_MUTATION,
    DELETE_UPLOADED_ARTWORK_ACTION,DELETE_UPLOADED_ARTWORK_MUTATION 

}

from "@/store/storeconstants";

const axios = require("axios");

export default {

    async [GET_PRODUCT_ACTION](context) {
        try {
            let response = await axios.get(`${process.env.VUE_APP_BASE_URL}product/getLeftMenuProducts`)
            if (response.status === 200) {
                context.commit(SET_PRODUCT_MUTATION,
                    response.data.data
                )
                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },

   async  [GET_SINGLE_PRODUCT_ACTION](context, payload) {
        try {
            console.log(payload, "GET_SINGLE_PRODUCT_ACTION")
            let response = await axios.get(`${process.env.VUE_APP_BASE_URL}product/${payload}`)
            // response.then(function(result){
            //     if (result.status == 200) {
            //         console.log(result, "get single product")
            //         context.commit(SET_SINGLE_PRODUCT_MUTATION, result.data.data)
            //         return result;
            //     }
            // })

            if (response.status == 200) {
                context.commit(SET_SINGLE_PRODUCT_MUTATION,
                    response.data.data
                )
               return response 
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },


    async [GET_ATTRIBUTES_DATA_ACTION](context, payload) {
       
        try {
            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}product/get_attributes`, payload)
          
            if (response.status === 200) {
                context.commit(SET_ATTRIBUTES_DATA_MUTATION,
                    response.data.data
                )
                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },

    async [GET_ARTWORK_DATA_ACTION](context, payload) {
      
        try {
            let template_id=payload;
            console.log('payload...?',payload);
            let response = await  axios.get(`${process.env.VUE_APP_BASE_URL}product/product_template/${template_id}`, payload)
       
            if (response.status === 200) {
                context.commit(SET_ARTWORK_DATA_MUTATION, 
                    response.data.data
                )

                return response.data;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },

    /***get artwork Upload */
    async [GET_ARTWORK_UPLOAD_ACTION](context, payload) {
        
         try {
            console.log(payload, 'payload from GET_ARTWORK_UPLOAD_ACTION')
            let response =  await axios.post(`${process.env.VUE_APP_BASE_URL}product/artwork_upload`, payload)
            console.log(response.data
                , 'response from GET_ARTWORK_UPLOAD_ACTION')

            if (response.status === 200) {
                 context.commit(SET_ARTWORK_UPLOAD_MUTATION, 
                     response.data.data
                 )
 
                return response;
             }
         } catch (error) {
              console.log(error);
         }
 
    },
    /***get artwork upload */

    /***get artwork framework */
    async [GET_FRAMEWORK_ACTION ](context, payload) {
        
        try {

            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}product/get_framework`, payload)
       
            if (response.status === 200) {
                context.commit(SET_FRAMEWORK_MUTATION, 
                    response.data.data
                )

                return response;
            }
        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response.data)
            throw errormsg;
        }

    },
    /***get artwork framework */


    /****save artwork framewoek template ***/
    async [SAVE_ARTWORK_ACTION](context, payload) {
        
        try {           
            console.log(payload, 'payload SAVE_ARTWORK_ACTION')
            const headers= {
                'Content-Type': 'multipart/form-data'
            }
            let response = await axios.post(`${process.env.VUE_APP_BASE_URL}product/saveArtwork`, payload, headers)
         
            if (response.status == 200) {  
                   console.log(response.data.data, 'response from SAVE_ARTWORK_ACTION')              
                context.commit(SAVE_ARTWORK_MUTATION,  
                    response.data.data
                )
                return response;
            }
        } catch (error) {
            return error;
            //throw errormsg;
        }

    },
    /****save artwork framewoek template ***/


    /***Delete uploaded artwork ****/
    [ DELETE_UPLOADED_ARTWORK_ACTION](context,payload) {
        
        try {    
            context.commit(DELETE_UPLOADED_ARTWORK_MUTATION,payload)
        } catch (error) {
            console.log(error)
             let errormsg = SignupValidations.getErrorMessageFromCode(error)
            throw errormsg;
        }

    }
    /***Delete uploaded artwork ****/
      

};