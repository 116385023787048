/* eslint-disable no-unused-vars */
import SignupValidations from "@/services/SignupValidations";

//import $ from 'jquery'
import {
    /*GET_USER_DATA_ACTION, SET_USER_DATA_MUTATION,*/
    GET_USER_PRINTING_ORDER_ACTION,
    SET_USER_PRINTING_ORDER_ACTION_MUTATION,
    GET_USER_COMMERCIAL_ORDER_ACTION,
    SET_USER_COMMERCIAL_ORDER_ACTION_MUTATION,
    GET_USER_SHIPPING_ACTION,
    SET_USER_SHIPPING_MUTATION,
    GET_ORDER_DETAILS_ACTION,
    SET_ORDER_DETAILS_MUTATION,
    GET_UPLOADED_ARTWORK_ACTION,
    SET_UPLOADED_ARTWORK_MUTATION,
    GET_USER_SHIPPING_ADDRESS_ACTION,
    SET_USER_SHIPPING_ADDRESS_MUTATION,
    CLEAR_USERINFO_ACTION,
    CLEAR_USERINFO_MUTATION,
    GETUPDATED_USER_SHIPPING_ADDRESS_ACTION,
    GETUPDATED_USER_SHIPPING_ADDRESS_MUTATION
} from "@/store/storeconstants";
//import Vue from 'vue';
// import Vuex from 'vuex';
// Vue.use(Vuex);

//import { toast } from 'vue3-toastify';
//import 'vue3-toastify/dist/index.css';

const axios = require("axios");

let storageData = "";
let tokenData = "";
let gettoken = "";



if (localStorage.vuex != undefined) {


    const storage = { ...localStorage };

    storageData = JSON.parse(storage.vuex);

    tokenData = (storageData != undefined) ? storageData.auth : "";

    // eslint-disable-next-line no-unused-vars
    let tokencheck = tokenData.CurrentUserLogin;

    if (Object.hasOwn(tokencheck, "CurrentUserLogin")) {
        let token1 = tokencheck.CurrentUserLogin

        if (Object.hasOwn(token1, "token")) {
            gettoken = token1.token;
        }
        else if (tokencheck.token !== "") {
            gettoken = tokencheck.token;
        }


    }


    //gettoken=(tokenData.CurrentUserLogin.CurrentUserLogin!=undefined)?tokenData.CurrentUserLogin.CurrentUserLogin:"";
}

console.log("in action for both" + gettoken)

export default {

    // async [GET_USER_DATA_ACTION](context) {
    //     try {
    //         //let token=store;
    //         if(gettoken!=undefined)
    //         {
    //             let response = await axios.get(`${process.env.VUE_APP_BASE_URL}user-data`,{
    //                 headers:{
    //                    'Authorization': `Bearer ${gettoken.token}`
    //                 }
    //                })
    //                   if (response.status === 200) {
    //                        context.commit(SET_USER_DATA_MUTATION,
    //                            response.data
    //                        )
    //                    }
    //         }



    //     } catch (error) {
    //         let errormsg = SignupValidations.getErrorMessageFromCode(error.response)

    //         throw errormsg;
    //     }

    // },


    /**********Printing orders ******************/
    // eslint-disable-next-line no-unused-vars
    async [GET_USER_PRINTING_ORDER_ACTION](context, payload) {
        try {
            console.log(payload, 'payload data');
            let token = gettoken
            if (token != "") {
                let response = await axios.get(`${process.env.VUE_APP_BASE_URL}getPrintingAndCommercialOrders?order_type=1&page=1&limit=2000000000`, 
                {
                    headers: {
                        'Authorization': `Bearer ${payload}`
                    }
                })
                console.log(response, "response out if from GET_USER_PRINTING_ORDER_ACTION")
                if (response.status === 200) {
                    console.log(response, "response in if from GET_USER_PRINTING_ORDER_ACTION")
                    context.commit(SET_USER_PRINTING_ORDER_ACTION_MUTATION,
                        response.data
                    )
                    return response.data;
                }
            }

        } catch (error) {
            console.log(error);
        }

    },
    /**********Printing Orders ******************/

    /**********Commercial Orders *****************/
    async [GET_USER_COMMERCIAL_ORDER_ACTION](context, payload) {
        try {
            //let token=store;

            let token = gettoken
            //let token=store;
            if (token !== "") {

                let response = await axios.get(`${process.env.VUE_APP_BASE_URL}getPrintingAndCommercialOrders?order_type=2&page=1&limit=2000000000`, {
                    headers: {
                        'Authorization': `Bearer ${payload}`
                    }
                })
                if (response.status === 200) {
                    context.commit(SET_USER_COMMERCIAL_ORDER_ACTION_MUTATION,
                        response.data
                    )

                    return response.data;
                }


            }

        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response)

            throw errormsg;
        }

    },
    /**********Commercial Orders ****************/

    // eslint-disable-next-line no-unused-vars
    async [GET_USER_SHIPPING_ACTION](context, payload) {
        try {
            let token = gettoken
            //let token=store;
            if (token !== "") {
                let response = await axios.get(`${process.env.VUE_APP_BASE_URL}cart/defaultShip`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
                if (response.status === 200) {
                    console.log(response.data, 'SET_USER_SHIPPING_MUTATION action')
                    localStorage.setItem("ShippingFromDetails", JSON.stringify(response.data));
                    context.commit(SET_USER_SHIPPING_MUTATION,
                        response.data
                    )
                    return response.data;
                }

            }

        } catch (error) {
            console.log(error)
        }

    },

    /****get artwork page *********************/
    async [GET_ORDER_DETAILS_ACTION](context, payload) {
        try {

            let order_id = payload.order_id;
            let token = payload.token
            //let token=store;
            if (token != "") {
                let response = await axios.get(`${process.env.VUE_APP_BASE_URL}getSinglePrintingOrder/${order_id}?order_type=1`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
                if (response.status === 200) {
                    console.log(response.data, "GET_ORDER_DETAILS_ACTION")
                    context.commit(SET_ORDER_DETAILS_MUTATION,
                        response.data
                    )

                    return response;
                }

            }

        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response)
            if (errormsg) {
                console.log(errormsg)
                // if (localStorage.getItem("Unauthenticated") == null) {
                //     localStorage.setItem("Unauthenticated", true);
                //   }
                //   else
                //   {
                //     localStorage.setItem("Unauthenticated", true);
                //   }

                //   setTimeout(() => {
                //     location.href="/#/"
                //   }, 1300);
            }
            throw errormsg;
        }

    },
    /*****get artwork page *********************/


    /*****post artwork upload page *************/
    // eslint-disable-next-line no-unused-vars
    async [GET_UPLOADED_ARTWORK_ACTION](context, payload) {
        try {
            if (gettoken !== "") {
                console.log(gettoken, payload.get("order_item"), "payload")
                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${gettoken}`
                }
                let response = await axios.post(`${process.env.VUE_APP_BASE_URL}uploadArtwork/${payload.get("order_item")}`, payload, headers)
                return response;
            }
        } catch (error) {
            console.log(error)
        }

    },
    /*****post artwork upload page *************/


    /******get user shipping address *********************/
    // eslint-disable-next-line no-unused-vars
    async [GET_USER_SHIPPING_ADDRESS_ACTION](context, payload) {


        try {
            //console.log(payload)

            let token = (payload !== undefined) ? payload : gettoken.token

            //let token=store;
            if (token !== "") {

                let response = await axios.get(`${process.env.VUE_APP_BASE_URL}address`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
                if (response.status === 200) {
                    context.commit(SET_USER_SHIPPING_ADDRESS_MUTATION,
                        response.data
                    )

                    return response;
                }


            }

        } catch (error) {
            let errormsg = SignupValidations.getErrorMessageFromCode(error.response)
            if (errormsg) {
                console.log(errormsg)
                // if (localStorage.getItem("Unauthenticated") == null) {
                //     localStorage.setItem("Unauthenticated", true);
                //   }
                //   else
                //   {
                //     localStorage.setItem("Unauthenticated", true);
                //   }

                //   setTimeout(() => {
                //     location.href="/#/"
                //   }, 1300);
            }
            throw errormsg;
        }

    },
    /******get user shipping address *********************/


    /*****clear usrinfo upon logout *****/
    async [CLEAR_USERINFO_ACTION](context) {
        context.commit(CLEAR_USERINFO_MUTATION,
            {}
        )
    },

    /*****clear userinfo upon logout *****/

    /***********GET UPDATED USER ADDRESSES *******/

    async [GETUPDATED_USER_SHIPPING_ADDRESS_ACTION](context, payload) {
        context.commit(GETUPDATED_USER_SHIPPING_ADDRESS_MUTATION,
            payload
        )
    }
    /***********GET UPDATED USER ADDRESSES *******/

};