import { SET_PRODUCT_MUTATION, SET_SINGLE_PRODUCT_MUTATION ,SET_ATTRIBUTES_DATA_MUTATION, SET_ARTWORK_DATA_MUTATION, SET_ARTWORK_UPLOAD_MUTATION,SET_FRAMEWORK_MUTATION,SAVE_ARTWORK_MUTATION,DELETE_UPLOADED_ARTWORK_MUTATION } from "@/store/storeconstants";
 

export default {
    [SET_PRODUCT_MUTATION](state, payload) {
        state.AllProducts = payload
    },
    
    [SET_SINGLE_PRODUCT_MUTATION](state, payload) {
        state.SingleProduct = payload
    },

    [SET_ATTRIBUTES_DATA_MUTATION](state,payload){
        state.Attributes = payload
    },


    [SET_ARTWORK_DATA_MUTATION](state, payload) {
        state.ArtWork = payload
    },

    [ SET_ARTWORK_UPLOAD_MUTATION](state, payload) {
        state.ArtworkUpload = payload
    },

    [ SET_FRAMEWORK_MUTATION](state, payload) {
        state.frameWork = payload
    },

    [ SAVE_ARTWORK_MUTATION ](state, payload) {
        console.log(payload, "mutaion......?????????????")
     
      
        state.saveArtwork = payload
    },

    [DELETE_UPLOADED_ARTWORK_MUTATION](state,payload){
     /****Removing Artwork Data from state  ****/
        state.ArtworkUpload={}
        state.ArtWork={}
        state.saveArtwork ={}
     /****Removing Artwork Data from state  ****/
    //  const store = useStore();
    //    let order_id="";
    //    order_id=payload.id;
  
      console.log('storedState...??----state', payload);
    //     let storedState=  state.cart; // state.cart;
    //     console.log('storedState...??',storedState);
    //     storedState.map(function(e){
    //         if(parseInt(e.id)===parseInt(order_id))
    //         {
    //         e.file_details=''
    //         }
    //     })

    ///console.log();
     
            
    }


    


    
    
   
};