import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state() {
        return {
            AllProducts: [],
            Attributes:[],
            ArtWork:[],
            ArtworkUpload:[],
            frameWork:[],
            saveArtwork:[],
            
          
        };
    },
    mutations,
    getters,
    actions,
}
