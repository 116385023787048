<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb align-items-center mb-0">
            <li class="breadcrumb-item">
              <a href="#" class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">Home</a>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/pages/printing"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">Printing</router-link>
            </li>
            <li class="breadcrumb-item font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none">
              <a class="text-grey" style="text-decoration: none" href="/#/cart">{{ breadcrumbs.cururl }}</a>
            </li>
            <!-- <li class="breadcrumb-item">
              <a
                :href="'#/product/' + first_product_url"
                class="font-12 font-family-Roboto font-weight-400 text-grey text-decoration-none"
              >
                {{ first_product_title }}</a
              >
            </li> -->
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <!--End container-->

  <div class="container mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul id="progress">
          <li class="font-14 font-family-Roboto font-weight-700 text-capitalize finish">
            my shopping cart
          </li>
          <li class="font-14 font-family-Roboto font-weight-700 text-capitalize">
            Shipping Details
          </li>
          <li class="font-14 font-family-Roboto font-weight-700 text-capitalize">
            Payment Details
          </li>
          <li class="font-14 font-family-Roboto font-weight-700 text-capitalize">
            Review Details
          </li>
          <li class="font-14 font-family-Roboto font-weight-700 text-capitalize">
            Checkout
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--Process Name-->

  <div class="container mt-4 mb-3">
    <div class="row">
      <div class="col-md-6">
        <div style="display: none" ref="cartitems" class="alert alert-success"></div>
        <h1 class="font-30 font-weight-700">Shopping Cart</h1>
      </div>
      <div v-if="Object.keys(getcartData).length > 0" class="col-md-6 text-end">
        <button id="clearcart" @click="clearCart()"
          class="bg-transparent border-0 font-14 font-family-Roboto text-decoration-underline text-capitalize font-weight-700">
          Clear cart
        </button>
      </div>
    </div>
  </div>
  <!--Cart Section-->

  <div class="container">
    <div class="row">
      <!-- <form  @submit.prevent="formSubmit($event)" action="" ref="form" enctype="multipart/form-data"> -->
      <div class="col-md-12">
        <div class="table-responsive cart_table">
          <div v-if="Object.keys(getcartData).length > 0">
            <table v-if="Object.keys(getcartData).length > 0" class="table table-borderless">
              <thead>
                <tr>
                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Item
                  </th>
                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Description
                  </th>

                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Qty
                  </th>
                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Price
                  </th>
                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Total
                  </th>
                  <th class="font-20 font-weight-700 font-family-Roboto">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cart, index) in cartData" v-bind:key="index">
                  <td class="align-middle">
                    <div class="align-items-center d-flex">
                      <div style="width: 80px; height: 80px">
                        <img class="h-100 img-fluid w-100" v-if="cart?.product_data?.product_files[0]?.file_name" :src="baseWebUrl +
                'files/image/' +
                cart?.product_data?.product_files[0]?.file_name
                " :alt="cart?.product_data?.title" />
                        <img v-else width="100" class="img-fluid" src="../assets/img/product_img.png" alt="" />
                      </div>

                      <div class="ms-3">
                        <span class="font-16 font-weight-400 font-family-Roboto"
                          v-html="cart.product_data.title"></span>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <span class="d-block font-family-Roboto font-weight-400 font-16"
                      v-html="cart.product_data.long_description"></span>
                    <div class="mt-2 mb-4" v-if="cart.cart_data">
                      <div v-for="(it, i) in cart.cart_data" :key="i">
                        <div v-if="it.text != '' &&
                it.value != '' &&
                it.id != 'user_filename' &&
                it.id != 'filenames_arr'
                ">
                          <b>{{ it.label }} {{ it.label ? ":" : "" }} </b>
                          {{ it.text }}
                        </div>
                      </div>
                    </div>

                    <div v-if="cart.file_details && cart.file_details != undefined">
                      <h6>Artwork Files</h6>
                      <div class="mt-4 mb-2" v-for="(files, index) in cart.file_details" v-bind:key="index">
                        <div v-if="typeof files != 'string' && files?.name != ''">
                          <!-- <span
                            class="mt-2 border-0 pb-2 pe-4 jk ps-4 pt-2 bg-grey rounded-3 font-14 font-weight-400 font-family-Roboto text-black-50 mt-2"
                            >{{ files.name }}
                          </span> -->

                          <span v-for="(file, i) in replaceCommaFromBrTag(files.name)" v-bind:key="i"  >
                             <span class="d-block mb-2 border-0 pb-2 pe-4 jk ps-4 pt-2 bg-grey rounded-3 font-14 font-weight-400 font-family-Roboto text-black-50 mt-2">{{ file }}</span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4 mt-2" v-if="cart?.file_details !== null &&
                Object.keys(cart?.file_details).length > 0
                ">
                      <a style="
                          font-size: 1.2em;
                          background: #8080801f;
                          padding: 4px;
                          text-decoration: none;
                        " :href="baseWebUrl +
                'files/order/' +
                cart.order_id +
                '/' +
                cart.order_id +
                '-' +
                cart.id +
                '.zip'
                " target="_blank">Download Artwork</a>&nbsp;<span class="ms-2" style="font-size: 1.1em">{{
                cart.artwork_files.name
              }}</span>
                      <span title="Remove Artwork" class="ms-2" style="cursor: pointer" @click.prevent="
              removeArtwork(
                $event,
                cart.order_id,
                cart.id,
                cart.product_data.id
              )
                "><img src="../assets/img/remove-icon.png" /></span>
                    </div>

                    <div v-else>
                      <span class="d-block font-family-Roboto font-weight-400 font-16"><input
                          @change="uploadFile($event, cart.order_id, cart.id)" class="filer file_Uploaded"
                          id="file_Uploaded"
                          accept="image/gif, image/jpeg, image/jpg, image/png, image/tiff, application/pdf, application/postscript, application/eps, application/x-eps, image/eps, image/x-eps, application/illustrator"
                          multiple="multiple" name="data[artwork][1501743][3674][741][]" type="file" /></span>
                      <div class="row cartpreview" id="filepreview"></div>
                      <h6 class="mt-4">Artwork Not Uploaded</h6>
                    </div>
                    <!---Artwork--->
                  </td>
                  <td class="align-middle font-16 font-family-Roboto font-weight-700">
                    <div class="d-flex justify-content-lg-between ProductQuantityBtn">
                      <input type="hidden" :id="`product_id_${index}`" :value="cart.product_id" />
                      <input type="hidden" :id="`product_price_${index}`" :value="cart.price" />
                      <input type="hidden" :id="`order_${index}`" :value="cart.order_id" />

                      <button class="decrement_btn bg-grey border-0" ref="decrement"
                        @click="updateQty($event, index, cart.id)">
                        -
                      </button>
                      <div>
                        <input class="bg-grey border-0 font-16 font-weight-700 font-family-Roboto ProductQuantity"
                          type="number" min="1" max="100" ref="qty" :id="`qty_${index}`" :value="quantity <= 1
                  ? cart.qty
                  : cartId == cart.product_id
                    ? quantity
                    : cart.qty
                " :data-qty="cart.qty" />
                      </div>
                      <button class="increment_btn bg-grey border-0" ref="increment"
                        @click="updateQty($event, index, cart.id)">
                        +
                      </button>
                    </div>
                  </td>

                  <td class="align-middle font-16 font-family-Roboto font-weight-700">
                    ${{ parseFloat(cart?.price).toFixed(2) }}
                  </td>
                  <td ref="total_amount" class="align-middle font-16 font-family-Roboto font-weight-700">
                    ${{ cart?.total_price }}
                  </td>

                  <td class="align-middle font-16 font-family-Roboto font-weight-700">
                    <button @click="
                delete_Item(cart.id, cart.price, index, cartTotal)
                "
                      class="bg-transparent border-0 font-14 font-family-Roboto text-decoration-underline text-capitalize font-weight-700 text-danger">
                      Remove
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div id="bottomalert" style="display: none" ref="cartitems2" class="alert alert-success"></div>
                  </td>
                </tr>

                <tr class="bg-black">
                  <td colspan="4" class="font-20 font-weight-700 font-family-Roboto text-white text-end">
                    Sub-Total
                  </td>
                  <td colspan="2" class="font-20 font-weight-700 font-family-Roboto text-white">
                    $ {{ cartTotal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-5 mb-5" v-else>
            <h2>Your Cart is Empty</h2>
          </div>
          <div style="display: none" class="cart-empty text-center mt-5 mb-5">
            <h2>Your Cart is Empty</h2>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(getcartData).length > 0" class="col-md-12 mt-5 mb-5 text-end">
        <div class="white_bg_btn">
          <a @click.prevent="updateCart()" class="btn btn-outline-primary me-sm-3 p-0 text-capitalize">Update cart</a>
          <a v-on:click.prevent="continueCheckout()" class="btn btn-primary p-0 text-capitalize">Continue</a>
          <a id="showuserlogin" data-bs-toggle="modal" data-bs-target="#userlogin"></a>
        </div>
      </div>
    </div>
  </div>
  <UserLogin />

  <LoaderHTML />
</template>

<script>
import $ from "jquery";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { ref } from "vue";
import "vue3-toastify/dist/index.css";
import { mapActions } from "vuex";
import { useRoute } from "vue-router";
import {
  GET_CART_QUANTITY_ACTION,
  DELETE_SINGLE_ITEM_CART_ACTION,
  DELETE_ALL_ITEMS_CART_ACTION,
  GET_UPDATE_CART_DATA_ACTION,
  CLEAR_CART_DATA_ACTION,
  DELETE_UPLOADED_ARTWORK_ACTION,
  DELETE_UPLOADED_CART_ARTWORK_ACTION,
  GET_USER_SHIPPING_ACTION,
  CLEAR_UP_LOGIN_ACTION,
  CLEAR_UP_LOGIN_FLAG_ACTION,
  CLEAR_USER_STORE_ACTION,
  DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
} from "@/store/storeconstants";
import UserLogin from "../components/UserLogin.vue";

import LoaderHTML from "@/components/LoaderHtml.vue";

export default {
  name: "CartView",
  components: {
    LoaderHTML,
    UserLogin,
  },
  data() {
    return {
      cartData: [],
      quantity: 1,
      price: 0,
      cartTotal: this.$store.state.cartData.cart.reduce(
        (accumulator, currentvalue) => {
          return (
            parseFloat(accumulator) + parseFloat(currentvalue?.total_price)
          ).toFixed(2);
        },
        0
      ),
      file_path:
        this.$store.state.cartData.cart.file_path != undefined
          ? this.$store.state.cartData.cart.file_path
          : "",
      first_product_title: "",
      first_product_url: "",
      total_price: 0,
      newprice: 0,
      cart_Total: ref(null),
      cartId: 0,
      myqt: 1,
      storeData: {},
      loginchooser: [],
      breadcrumbs: {
        prevurl: "",
        product: [],
        cururl: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.prevRoute && vm.prevRoute != undefined) {
        vm.prevRoute = from;
      }
    });
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    let getcartData = store.state.cartData.cart;
    // console.log("getcartData..", store.state.cartData);
    let userData = store.state.auth.CurrentUserLogin.CurrentUserLogin;
    const baseWebUrl = process.env.VUE_APP_BASE_URL_WEB;
    // let cartTotal = store.state.cartData.cart.reduce((accumulator, currentvalue) => {
    //     return (parseFloat(accumulator) + parseFloat(currentvalue.total_price)).toFixed(2)
    // }, 0);

    // const ListCartItems = async () => {
    //     all_CartItems(cartData)
    // }

    // onMounted(ListCartItems);

    function all_CartItems(allcartData) {
      console.log(allcartData, "allcartData")
      let allCartItems = [];
      let p_price = [];

      allcartData.forEach(function (e) {
        let product_id = e?.product_data?.id;
        let product_sku =
          e?.product_data?.sku !== undefined ? e?.product_data?.sku : "";
        let product_title = e?.product_data?.title;
        let product_description = e?.product_data?.long_description;
        let product_price = parseFloat(e?.price).toFixed(2);
        let total_price = parseFloat(e?.total_price).toFixed(2);

        p_price.push(total_price);

        let order_id = parseInt(e?.id);

        let product_qty = parseInt(e?.qty);

        let cartDataObj = e?.cart_data;
        let cartData = Object.values(cartDataObj);

        let optionData = [];
        cartData.map(function (e) {
          let optionsArray = {
            id: e?.id,
            val: e?.val,
            filename: e?.value,
          };
          optionData.push(optionsArray);
        });

        let fullCartData = {
          order_id: order_id,
          product_id: product_id,
          sku: product_sku,
          title: product_title,
          long_description: product_description,
          price: product_price,
          qty: product_qty,
          options: optionData,
          total_price: total_price,
        };

        allCartItems.push(fullCartData);
      });

      // let g_price = p_price.reduce((a, b) => {
      //     return (parseFloat(a) + parseFloat(b)).toFixed(2);
      // }, 0);

      //cartTotal = g_price;

      getcartData = allCartItems;
    }

    return {
      store,
      //cartTotal,
      getcartData,
      all_CartItems,
      baseWebUrl,
      userData,
      route,
    };
  },
  beforeMount() {
    let getitems = this.$store.state.cartData.cart;
    let p_title = "";
    let p_url = "";
    getitems.map((e, i) => {
      if (i == 0) {
        console.log(e);
        p_title = e?.product_data?.title;
        p_url = e?.product_data?.url;
      }
    });

    this.first_product_title = p_title;
    this.first_product_url = p_url;

    $(".loader").show("slow");
  },
  computed: {
    getProducts() {
      let products_title = [];

      $.each(this.getcartData, function (i, e) {
        products_title.push(e.product_data.title);
      });
      return products_title;
    },
    getuserToken() {
      let token_user = this.userData?.token != "" ? this.userData?.token : "";
      return token_user;
    },
  },

  mounted() {
    setTimeout(() => {
      $(".loader").hide("slow");
    }, 5000);

    //save user cart details***/
    if (this.getuserToken) {
      this.UserCartDetails(this.getuserToken)
        .then((res) => {
          console.log(res, "UserCartDetails from CartView Page");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //save user cart details***/

    this.breadcrumbs.prevurl = "Printing";

    this.breadcrumbs.cururl =
      this.route.name != undefined ? this.route.name : "";
    //this.breadcrumbs.product = this.getProducts;

    window.scrollTo(0, 0);

    // this.$refs.cartitems.style.display = "block";
    // this.$refs.cartitems.innerHTML = "Item Added Successfully"

    // this.$refs.cartitems2.style.display = "block";
    // this.$refs.cartitems2.innerHTML = "Item Added Successfully"

    // let loc = location.href;

    // setTimeout(() => {
    //     if (loc.indexOf('cart') > -1) {
    //         this.$refs.cartitems.style.display = "none";
    //         this.$refs.cartitems.innerHTML = " ";

    //         this.$refs.cartitems2.style.display = "none";
    //         this.$refs.cartitems2.innerHTML = " ";
    //     }
    // }, 3200)

    /***get item quantity ***/

    this.cartTotal;
  },
  methods: {
    ...mapActions("cartData", {
      updateCartData: GET_CART_QUANTITY_ACTION,
    }),

    ...mapActions("cartData", {
      updatedCartFiles: GET_UPDATE_CART_DATA_ACTION,
    }),

    ...mapActions("cartData", {
      deletedItem: DELETE_SINGLE_ITEM_CART_ACTION,
    }),

    ...mapActions("cartData", {
      deletedAllItem: DELETE_ALL_ITEMS_CART_ACTION,
    }),

    ...mapActions("cartData", {
      clearCartItems: CLEAR_CART_DATA_ACTION,
    }),

    ...mapActions("product", {
      deleteArtwork: DELETE_UPLOADED_ARTWORK_ACTION,
    }),
    ...mapActions("cartData", {
      deletecartArtwork: DELETE_UPLOADED_CART_ARTWORK_ACTION,
    }),

    ...mapActions("cartData", {
      deletesinglecartArtwork: DELETE_SINGLE_UPLOADED_CART_ARTWORK_ACTION,
    }),

    ...mapActions("userInfo", {
      UserCartDetails: GET_USER_SHIPPING_ACTION,
    }),

    ...mapActions("auth", {
      loggedOut: CLEAR_UP_LOGIN_ACTION,
    }),
    ...mapActions("auth", {
      loggedoutflag: CLEAR_UP_LOGIN_FLAG_ACTION,
    }),

    ...mapActions("auth", {
      userstoreEmpty: CLEAR_USER_STORE_ACTION,
    }),

    replaceCommaFromBrTag(files) {
        const newData = files.split(',');
        var rv = {};
         for (var i = 0; i < newData.length; ++i)
         if (newData[i] !== undefined) rv[i] = newData[i];
       return rv;
       
    },

    // ...mapActions("UserInfo", {
    //     setUserToken: GET_USER_DATA_ACTION
    // }),
    getArtwork_Details() {
      let artworkfiles =
        localStorage.getItem("artworkFiles") !== ""
          ? JSON.parse(localStorage.getItem("artworkFiles"))
          : "";
      let files = {};
      if (artworkfiles != undefined && artworkfiles !== "") {
        let getfilename = artworkfiles.file_name;
        let getfilepath = artworkfiles.file_path;

        files = {
          file_name: getfilename,
          file_path: getfilepath,
        };
      } else {
        ///
        /// let files = {};
        let fileData = this.$store.state.product.saveArtwork;
        console.log(fileData, "fileData");

        if (fileData != undefined && fileData !== "") {
          files = {
            file_name: fileData.file,
            file_path: fileData.path,
          };
        }
      }

      return files;
    },

    uploadFile(e, order_id, item_id) {
      let fileList = e.target.files;

      let formData = new FormData();

      formData.append("data[order_id]", order_id);
      formData.append("data[item_id]", item_id);

      $.each(fileList, function (i, e) {
        formData.append("data[File][image][" + i + "]", e);
      });

      this.updatedCartFiles(formData)
        .then((res) => {
          if (res.data.message) {
            let msg = res.data.message;
            toast.success(msg, { autoClose: 700 });
          }
        })
        .catch((error) => {
          toast.error("sorry there is some error,please try later", {
            autoClose: 700,
          });
          console.log(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    removeArtwork(event, order_id, item_id, product_id) {
      const removeartwork = confirm("Are you Sure you want to remove Artwork?");
      if (removeartwork == true) {
        let getfilename = event.target.parentElement.previousElementSibling;
        let file_name = getfilename.innerText;
        if (localStorage.getItem("artworkFiles") !== null) {
          localStorage.removeItem("artworkFiles");
        }
        //console.log(event.target)
        let result = this.$axios.get(
          `${process.env.VUE_APP_BASE_URL}cart/remove_artwork/${order_id}/${item_id}/${product_id}`
        );
        result
          .then((res) => {
            if (res.status == 200) {
              let artwork_file_name = {
                file: file_name,
              };
              this.deletecartArtwork(artwork_file_name);
              this.deletesinglecartArtwork(res);
              toast.success(
                "Artwork file have been Successfully removed from Cart",
                {
                  autoClose: 2000,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    clearCart() {
      let input = confirm("Are you sure you want to clear cart?");
      if (input && input == true) {
        this.deletedAllItem();
        this.clearCartItems({});
        //this.store.state.cartData.cart = [];
        $("table thead").empty();
        $("table tbody").empty();
        $(".white_bg_btn").empty();
        $("#clearcart").empty();
        $(".cart-empty").show("slow");

        toast.success("cart cleared successfully", { autoClose: 740 });
      }
    },
    delete_Item(order_id, price, index, Total) {
      let input = confirm("Are you sure you want to delete?");
      let deleted_item = {};
      if (input && input == true) {
        deleted_item = {
          order_id: order_id,
        };

        this.deletedItem(deleted_item); //delete item from virtual dom
        // this.cartData.splice(order_id, 1) //remove item from store
        this.cartData.splice(
          this.cartData.findIndex(function (i) {
            return i.id === order_id;
          }),
          1
        );
        // this.cartData = [...this.$store.state.cartData.cart]
        //console.log("this.$store.state.cartData.cart", this.cartData, deleted_item)
        let remove_price = (parseFloat(Total) - parseFloat(price)).toFixed(2);
        this.cartTotal = remove_price; //Update Total Cart Price

        toast.success("Item deleted successfully", { autoClose: 740 });
      }
    },

    updateQty(e, index, item_id) {
      let operator = e.target.innerText;

      console.log("index...???", index);

      let product_actual_price = parseFloat(
        e.target.parentElement.children[1].value
      ).toFixed(2);
      let product_id = e.target.parentElement.children[0].value;
      let order_id = e.target.parentElement.children[2].value;

      let price_total = 0;
      let total_sum = [];
      let alldata = {};
      let cart_total = 0;

      //let up_qty=1;
      let newprice = 0;

      switch (operator) {
        case "+":
          this.myqt = ++this.$store.state.cartData.cart[index].qty;

          this.cartId = this.$store.state.cartData.cart[index].product_id;

          // this.cartData[index].qty = this.myqt
          // my_qty=e.target.previousElementSibling.getAttribute('id')

          //  document.getElementById(my_qty).value=this.myqt;

          newprice = (product_actual_price * this.myqt).toFixed(2);

          e.target.parentElement.parentElement.nextElementSibling.nextElementSibling.innerText =
            newprice;
          //this.cartTotal=(parseFloat(e.target.parentElement.parentElement.nextElementSibling.nextElementSibling.innerText)).toFixed(2)
          alldata = {
            product_id: product_id,
            order_id: order_id,
            item_id: item_id,
            qty: this.myqt,
          };
          this.updateCartData(alldata); // Update Cart Quantity

          this.quantity = alldata.qty;

          /******Update Item in Store*********/
          this.$store.state.cartData.cart[index].qty = this.myqt;
          this.$store.state.cartData.cart[index].total_price = newprice;

          //this.$store.state.cartData.cart[index].price = newprice ;

          price_total = this.$refs.total_amount;

          price_total.forEach(function (e) {
            total_sum.push(e.innerText);
          });

          /*******************Cart Total  **********************/
          cart_total = total_sum.reduce((acculumator, currentvalue) => {
            return (parseFloat(acculumator) + parseFloat(currentvalue)).toFixed(
              2
            );
          }, 0);

          this.$store.state.cartData.cart.cart_total = cart_total;
          /*******************Cart Total  **********************/

          /******Update Item in Store*********/
          this.cartTotal = cart_total;

          // console.log(e.target.parentElement.parentElement.parentElement)

          toast.success("Cart updated successfully", {
            autoClose: 500,
          });

          //this.myqt++;
          break;
        case "-":
          console.log("this.myq.---..", this.myqt);
          if (this.myqt < 2) {
            this.myqt = 1;
            toast.error("Sorry quantity cant be less than 1", {
              autoClose: 600,
            });
            // document.getElementById(my_qty).value=1;
            return false;
          }
          this.myqt = --this.$store.state.cartData.cart[index].qty;

          this.cartId = this.$store.state.cartData.cart[index].product_id;
          // this.cartData[index].qty = this.myqt
          // my_qty=e.target.nextElementSibling.getAttribute('id')

          // document.getElementById(my_qty).value=this.myqt;
          // console.log(my_qty)

          newprice = (product_actual_price * this.myqt).toFixed(2);

          e.target.parentElement.parentElement.nextElementSibling.nextElementSibling.innerText =
            newprice;
          //this.cartTotal=(parseFloat(e.target.parentElement.parentElement.nextElementSibling.nextElementSibling.innerText)).toFixed(2)
          alldata = {
            product_id: product_id,
            order_id: order_id,
            item_id: item_id,
            qty: this.myqt,
          };
          this.updateCartData(alldata); // Update Cart Quantity
          this.quantity = alldata.qty;

          /******Update Item in Store*********/
          this.$store.state.cartData.cart[index].qty = this.myqt;
          this.$store.state.cartData.cart[index].total_price = newprice;
          //this.$store.state.cartData.cart[index].price = newprice ;

          price_total = this.$refs.total_amount;
          price_total.forEach(function (e) {
            total_sum.push(e.innerText);
          });

          /*******************Cart Total  **********************/
          cart_total = total_sum.reduce((acculumator, currentvalue) => {
            return (parseFloat(acculumator) + parseFloat(currentvalue)).toFixed(
              2
            );
          }, 0);

          this.$store.state.cartData.cart.cart_total = cart_total;
          /*******************Cart Total  **********************/

          /******Update Item in Store*********/
          this.cartTotal = cart_total;
          // console.log(e.target.parentElement.parentElement.parentElement)

          toast.success("Cart updated successfully", {
            autoClose: 500,
          });

          break;
      }
    },
    updateCart() {
      toast.success("Cart updated successfully", {
        autoClose: 500,
      });
    },
    // updateFiles(order_id,item_id,fileData)
    // {
    //     let formData=new FormData();

    //     formData.append("data[order_id]",order_id)
    //     formData.append("data[item_id]",item_id)

    //     $.each(fileData,(i,e)=>{

    //         formData.append("data[File][image]["+i+"]",e)
    //     });

    //     this.updatedCartFiles(formData)

    // }

    continueCheckout() {
      let getUserData = this.store.state.auth.CurrentUserLogin.CurrentUserLogin;
      let checklogin = this.store.state.auth.userLoggedin;
      console.log("getUserData...", getUserData);
      //
      //Object.hasOwn(getUserData, "token") &&
      //getUserData.token != "" &&
      if (checklogin == true) {
        this.$router.push("/shipping");
      } else {
        document.getElementById("showuserlogin").click(); //trigger login popup
      }
      // if (getUserData === "undefined" || getUserData == "") {
      //   document.getElementById("showuserlogin").click(); //trigger login popup
      // } else {
      //   this.$router.push("/shipping");
      // }
    },
  },

  updated() {
    this.cartData = this.$store.state.cartData.cart;
    console.log("getcartData.....???", this.cartData);
    this.all_CartItems(this.cartData);
    this.cartTotal = this.$store.state.cartData.cart.reduce(
      (accumulator, currentvalue) => {
        return (
          parseFloat(accumulator) + parseFloat(currentvalue.total_price)
        ).toFixed(2);
      },
      0
    );
  },
  created() {
    document.title = "Cart";
  },
};
</script>

<style scoped>
button {
  font-size: 1em !important;
}

#bottomalert {
  position: absolute;
  margin-left: 800px;
  width: 300px;
  margin-top: -40px;
}

.cart_table table tbody tr:last-child td {
  background: black;
}

.cart_table table tbody tr:last-child td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.cart_table table tbody tr:last-child td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.cart_table table tbody tr:last-child td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.cart_table table tbody tr:nth-last-child(-1n + 2) td {
  border-bottom: 0;
}

button#updatefiles {
  font-size: 0.8em !important;
}
</style>
