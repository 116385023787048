import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "./modules/auth";
import product from "./modules/product";
import home from "./modules/home";
import cart from "./modules/cart";
import user from "./modules/user";
import location from "./modules/location";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

const store = createStore({
    modules: {
        auth: auth,
        product: product,
        homeData: home,
        cartData: cart,
        userInfo: user,
        location:location,
    },
    mutations: {
    removeArtworData(state) {
      state.product.saveArtwork = null; //  Or you can set it to some default value
    },
  },
    plugins: [vuexLocal.plugin],
});
 
export default store;