<template>
  <div class="orderdetail mt-5 container">
    <div class="row">
      <div v-if="getOrders !== undefined" class="col-12">
        <div class="callout callout-info">
          <h5>
            <i class="fa fa-info"></i
            ><b
              >Order Status: Order Created paid Cash/Check. Please confirm the
              payments and change the status accordingly.</b
            >
          </h5>
        </div>

        <!-- Main content -->
        <div class="invoice mt-3 mb-3">
          <!-- title row -->
          <div class="row">
            <div class="col-md-12">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Last Change Date/Time</strong></td>
                    <td>{{ getUserOrders.updated_at }}</td>
                  </tr>
                  <tr>
                    <td width="50%"><strong>Order Date/Time</strong></td>
                    <td>{{ getUserOrders.created_at }}</td>
                  </tr>

                  <tr>
                    <td><strong>Order Number</strong></td>
                    <td>{{ "#" + getUserOrders.order_id }}</td>
                  </tr>
                  <tr>
                    <td><strong>Reference Number</strong></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><strong>IP Address</strong></td>
                    <td>{{ getUserOrders.ip_address }}</td>
                  </tr>

                  <tr>
                    <td><strong>Customer Number</strong></td>
                    <td>{{ getUserOrders.user_id }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- /.col -->
          </div>
          <!-- info row -->
          
          <hr />
          <div class="row">
            <div class="input">
              <div>
                <strong>TERMS OF SERVICE</strong>

                <div class="mt-2">
                  <font color="#ee282d"
                    >Once we create your Print Line Processing Number you CANNOT
                    CANCEL / MODIFY / UPGRADE YOUR ORDER under any
                    circumstances.</font
                  >
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div class="row invoice-info mb-4">

            <div class="col-sm-4 invoice-col">
                <b>From</b>
                <address class="h-100 mb-0">

                    <div class="">
                        <strong>
                            {{getUserOrders.from_ship?.first_name != undefined ? getUserOrders.from_ship?.first_name : " "}}
                            {{getUserOrders.from_ship?.last_name != undefined ? getUserOrders.from_ship?.last_name : "" }} 
                        </strong>
                        <br />

                        {{ getUserOrders.from_ship?.company }}<br />
                        {{ getUserOrders.from_ship?.address1 != "" ? getUserOrders.from_ship?.address1 : ""  }}
                        <div>{{ getUserOrders.from_ship?.address2 }}</div>
                        <div>{{ getUserOrders?.fromShipCountry }}, {{ getUserOrders?.fromShipState }}, {{ getUserOrders?.fromShipCity }} </div>
                        <div>{{ getUserOrders?.from_ship?.zip }} </div>
                    </div>
                    
                    <div class="mt-4">
                        US Day Phone: {{ getUserOrders.from_ship?.day_phone }}<br />
                        Night Phone: {{ getUserOrders.from_ship?.night_phone }} <br />
                        Email:
                        {{
                        getUserOrders.from_ship?.email != undefined &&
                        getUserOrders.from_ship?.email !== ""
                            ? getUserOrders.from_ship?.email
                            : ""
                        }}
                    </div>

                </address>
            </div>
            <!-- /.col -->

            <div class="col-sm-4 invoice-col">
                <strong>To</strong>
                <address class="h-100 mb-0">
                    <div class="">
                        <strong>
                            {{ console.log(getUserOrders, "Shipping To") }}
                            {{ getUserOrders.to_ship?.first_name != undefined ? getUserOrders.to_ship.first_name : " " }}
                            {{ getUserOrders.to_ship?.last_name != undefine ? getUserOrders.to_ship?.last_name : ""}}
                        </strong>
                        <br />

                        {{ getUserOrders.to_ship?.company }}<br />
                        {{ getUserOrders.to_ship?.address1 }}
                        <div>{{ getUserOrders.to_ship?.address2 }}</div>
                        <div>{{ getUserOrders?.toShipCountry }}, {{ getUserOrders?.toShipState }}, {{ getUserOrders?.toShipCity }} </div>
                        <div>{{ getUserOrders.to_ship?.zip }}</div>
                    </div>
                    
                    <div class="mt-4">
                        US Day Phone: {{ getUserOrders.to_ship?.day_phone }}<br />
                        Night Phone: {{ getUserOrders.to_ship?.night_phone }} <br />
                        Email:
                        {{ getUserOrders.to_ship?.email != undefined && getUserOrders.to_ship?.email !== "" ? getUserOrders.to_ship?.email : "" }}
                    </div>
                </address>
            </div>
            
            <!-- /.col -->
            <div class="col-sm-4 invoice-col">
              <b>Method: </b>{{ getUserOrders.payment_type }}<br />
              <br />
              <b>Price:</b> {{ parseFloat(getUserOrders.price_total).toFixed(2)
              }}<br />
            </div>
            <!-- /.col -->

          </div>
          <!-- /.row -->

            <!-- Table row -->
            
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Qty</th>
                                <th>Attributes</th>
                                <th>Shipping From</th>
                                <th>Shipping Cost</th>
                                <th>File Name</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr  v-for="(order, index) in order_Details.items"
                          :key="index">
                           
                                <td>
                                {{ order.qty }}
                                </td>

                                <td>
                                  <span class="mb-2 d-block" v-html="order?.product_data?.title ? order?.product_data?.title:''"></span>
                                <!-- <span class="mb-2 d-block">{{ order?.product_data?.title }}</span> -->

                                <span v-for="(o_d, ind) in order.details_text" :key="ind">
                                  <!-- {{ o_d }} -->
                                    <p v-html="o_d"></p>
                                </span>

                                <!-- <a
                                    v-if="order_Details?.files_name"
                                    :href="
                                    $baseurlForImages +
                                    'files/order/' +
                                    getUserOrders?.order_id +
                                    '/' +
                                    order_Details?.files_name
                                    "
                                    >Download Artwork
                                </a> -->
                                </td>

                                <td>
                                {{
                                    order.shipping_quote.shipData?.from?.name != ""
                                    ? order.shipping_quote.shipData?.from?.name
                                    : ""
                                }}<br />
                                </td>

                                <td>
                                $
                                {{
                                    order_Details.price_shipping !== ""
                                    ? parseFloat(
                                        order_Details.price_shipping * order.qty
                                        ).toFixed(2)
                                    : 0.0
                                }}
                                </td>
                                <!-- {{ order.files_data }}
                                {{ order.cart_data }} -->
                                <td>
                                    <span v-if="order.files_data != ''">
                                        <span v-for="(o_d, ind) in order.cart_data" :key="ind">
                                            <span v-if="o_d.id === 'filenames_arr'">
                                                <span v-if="o_d.value != ''">
                                                    <span v-for="(file, fileKey) in o_d.value" :key="fileKey"> {{ file }} <br> </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </td>

                                <td>
                                    <div> $  {{ parseFloat(order.price * order.qty).toFixed(2) }} </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- /.col -->
            </div>

            <!-- /.row -->
            <div class="row">
                <!-- accepted payments column -->
                <div class="col-12">
                    <!--<p class="lead">Amount Due 2/22/2014</p> -->
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr>
                                <th style="width: 50%">Sub Total:</th>
                                <td>
                                    $ {{ parseFloat(order_Details.item_cost).toFixed(2) }}
                                </td>
                                </tr>
                                <tr>
                                <th style="width: 50%">Shipping Cost</th>
                                <td>
                                    $
                                    {{
                                    order_Details.shipping_price_total !== ""
                                        ? parseFloat(
                                            order_Details.shipping_price_total
                                        ).toFixed(2)
                                        : 0.0
                                    }}
                                </td>
                                </tr>

                                <tr>
                                <th>Tax</th>
                                <td>
                                    $
                                    {{
                                    order_Details.price_taxes !== ""
                                        ? parseFloat(order_Details.price_taxes).toFixed(2)
                                        : 0.0
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <th>Discount:</th>
                                <td>
                                    {{
                                    order_Details.discount !== ""
                                        ? "$ - " +
                                        parseFloat(order_Details.discount).toFixed(2)
                                        : 0.0
                                    }}
                                </td>
                                </tr>
                                <tr>
                                <th>Total Amount:</th>
                                <td>
                                    <div v-if="order_Details.total_price != ''">
                                    {{
                                        "$" +
                                        parseFloat(order_Details.total_price).toFixed(2)
                                    }}
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->

          <!-- this row will not appear when printing -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->

      <form
        @submit.prevent="setBilling($event)"
        action=""
        ref="form"
        enctype="multipart/form-data"
        accept-charset="UTF-8"
      >
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  @click.prevent="setBillinginfo()"
                  class="btn btn-primary collapsed"
                  type="button"
                  aria-controls="collapseThree"
                >
                  Setup Billing Information
                </button>
              </h2>
            </div>
            <div
              style="display: none"
              id="billingdetail"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="row no-print">
                  <input
                    type="hidden"
                    name="shipping[to][id]"
                    id="shipping_to_id"
                    :value="order_Details?.toShip?.id"
                  />
                  <input
                    type="hidden"
                    id="shipping_to_order_id"
                    name="shipping[to][order_id]"
                    :value="order_Details.id"
                  />
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Processing">First Name</label>
                      <input
                        class="form-control"
                        name="shipping[to][first_name]"
                        id="shipping_to_first_name"
                        :value="order_Details?.toShip?.first_name"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Last Name</label>
                      <input
                        name="shipping[to][last_name]"
                        id="shipping_to_last_name"
                        :value="order_Details?.toShip?.last_name"
                        class="form-control"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Title</label>
                      <input
                        name="shipping[to][title]"
                        id="shipping_to_title"
                        :value="order_Details?.toShip?.title"
                        class="form-control"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Company</label>
                      <input
                        name="shipping[to][company]"
                        id="shipping_to_company"
                        :value="order_Details?.toShip?.company"
                        class="form-control"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Address 1</label>
                      <input
                        name="shipping[to][address1]"
                        id="shipping_to_address1"
                        :value="order_Details?.toShip?.address1"
                        class="form-control"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Address 2</label>
                      <input
                        name="shipping[to][address2]"
                        id="shipping_to_address2"
                        :value="order_Details?.toShip?.address2"
                        class="form-control"
                        maxlength="255"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Country</label>
                      <select
                        v-model="shiptoSubmitted.country"
                        id="ship_to_country"
                        @change.prevent="getShipToCountry($event)"
                        class="form-control"
                        name="shipping[to][country]"
                      >
                        {{
                          shiptoSubmitted
                        }}
                        <option
                          v-for="country in countriesShipto"
                          :value="country.id"
                          :key="country.id"
                          :id="'ship_to_country-' + country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">State</label>

                      <select
                        name="shipping[to][state_id]"
                        v-model="shiptoSubmitted.state"
                        id="ship_to_state"
                        @change.prevent="getshipToStates($event)"
                        class="form-control border-0 rounded-0 font-family-Roboto font-14"
                      >
                        <option
                          v-for="state in statesShipto"
                          :key="state.id"
                          :value="state.id"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">City</label>
                      <select
                        name="shipping[to][city]"
                        v-model="shiptoSubmitted.city"
                        @change.prevent="getshipToCity($event)"
                        id="ship_to_city"
                        class="form-control border-0 rounded-0 font-family-Roboto font-14"
                      >
                        <option selected value="">Select city</option>
                        <option
                          v-for="city in citiesShipto"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Zip</label>
                      <input
                        name="shipping[to][zip]"
                        id="shipping_to_zip"
                        class="form-control"
                        maxlength="255"
                        type="text"
                        :value="order_Details?.toShip?.zip"
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Day Phone</label>
                      <input
                        name="shipping[to][day_phone]"
                        id="shipping_to_day_phone"
                        class="form-control"
                        maxlength="255"
                        type="text"
                        :value="order_Details?.toShip?.day_phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Night Phone</label>
                      <input
                        name="shipping[to][night_phone]"
                        id="shipping_to_night_phone"
                        class="form-control"
                        maxlength="255"
                        type="text"
                        :value="order_Details?.toShip?.night_phone"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="OrderProcessTrackingNo">Email</label>
                      <input
                        name="shipping[to][email]"
                        id="shipping_to_email"
                        class="form-control"
                        maxlength="255"
                        type="email"
                        :value="order_Details?.toShip?.email"
                      />
                    </div>
                  </div>
                </div>

                <div class="row no-print">
                  <div class="col-12 text-right">
                    <input
                      class="btn btn-lg btn-primary"
                      name="data[Order][save]"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- /.container-fluid -->
  </div>
</template>

<script>
import $ from "jquery";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { mapActions } from "vuex";
import { toast } from "vue3-toastify";
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import {
  GET_ORDER_DETAILS_ACTION,
  GET_COUNTRY_ACTION,
  GET_STATE_ACTION,
  GET_CITY_ACTION,
} from "@/store/storeconstants";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
//import { onMounted } from "vue";

export default {
  name: "OrderList",
  data() {
    return {
      order_Details: [],
      item_details: [],
      user_token: null,
      countriesShipto: ["Select Country"],
      statesShipto: [],
      citiesShipto: [],
      shipping_to_id: "",
      shiptoSubmitted: {
        country: "",
        state: "",
        city: "",
      },
    };
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const order_id = route.params.id;
    const token = store.state.auth.CurrentUserLogin.CurrentUserLogin.token;

    return {
      order_id,
      store,
      token,
    };
  },

  methods: {
    ...mapActions("userInfo", {
      orderDetails: GET_ORDER_DETAILS_ACTION,
    }),
    /****Fetch countries, states and cities *****/
    ...mapActions("location", {
      listCountries: GET_COUNTRY_ACTION,
    }),

    ...mapActions("location", {
      listStates: GET_STATE_ACTION,
    }),

    ...mapActions("location", {
      listCities: GET_CITY_ACTION,
    }),
    setBillinginfo() {
      if ($("#billingdetail").is(":visible") == false) {
        $("#billingdetail").show("slow");
      } else {
        $("#billingdetail").hide("slow");
      }
    },
    setBilling(event) {
      let formData = new FormData();
      event.preventDefault();
      // Prevent the default form submission behavior
      let config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      formData.append("shipping[to][id]", $("#shipping_to_id").val());
      formData.append(
        "shipping[to][order_id]",
        $("#shipping_to_order_id").val()
      );
      formData.append(
        "shipping[to][first_name]",
        $("#shipping_to_first_name").val()
      );
      formData.append(
        "shipping[to][last_name]",
        $("#shipping_to_last_name").val()
      );
      formData.append("shipping[to][title]", $("#shipping_to_title").val());
      //ship_to_country

      formData.append("shipping[to][company]", $("#shipping_to_company").val());
      formData.append(
        "shipping[to][last_name]",
        $("#shipping_to_last_name").val()
      );
      formData.append(
        "shipping[to][address1]",
        $("#shipping_to_address1").val()
      );
      formData.append(
        "shipping[to][address2]",
        $("#shipping_to_address2").val()
      );
      formData.append("shipping[to][country]", $("#ship_to_country").val());
      ///
      formData.append("shipping[to][state_id]", $("#ship_to_state").val());
      formData.append("shipping[to][city]", $("#ship_to_city").val());
      formData.append("shipping[to][zip]", $("#shipping_to_zip").val());
      formData.append("shipping[to][email]", $("#shipping_to_email").val());
      formData.append(
        "shipping[to][day_phone]",
        $("#shipping_to_day_phone").val()
      );
      formData.append(
        "shipping[to][night_phone]",
        $("#shipping_to_night_phone").val()
      );

      this.$axios
        .post(
          process.env.VUE_APP_BASE_URL + "orderShipUpdate",
          formData,
          config
        )
        .then((res) => {
          console.log(res.data, "res.data...???");
          if (res.data.status == 1) {
            toast.success("uploaded successfully", {
              autoClose: 2400,
            });
          } else {
            toast.error("some thing went worng!", {
              autoClose: 2500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("some thing went worng!", {
            autoClose: 2500,
          });
        });
      console.log("hi.....submit");
    },
  },
  beforeMount() {
    let orders = {
      order_id: this.order_id,
      token: this.user_token,
    };

    this.orderDetails(orders)
      .then((res) => {
        console.log("res.data.data....???", res.data.data);
        // this.order_Details = res.data.data;
        let order_Details = res.data.data;

        let shiptocountry = order_Details.toShip.country;
        let shiptostate = order_Details.toShip.state_id;
        let shipcity = order_Details.toShip.city;
        this.listCountries()
          .then((res) => {
            if (res.status == 200) {
              this.shiptoSubmitted.country = shiptocountry;
              this.countriesShipto = res.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });

        let getshiptocountry = {
          country_id: shiptocountry,
        };

        this.listStates(getshiptocountry)
          .then((resstate) => {
            if (resstate.status == 200) {
              // this.submittedData.state = this.getDefaultShipping.state_id;
              this.shiptoSubmitted.state = shiptostate;
              this.statesShipto = resstate.data.data;
            }
          })
          .catch((cityerror) => {
            console.log(cityerror);
          });

        let getshiptostates = {
          state_id: shiptostate,
        };

        this.listCities(getshiptostates)
          .then((citydata) => {
            if (citydata.status == 200) {
              //this.submittedData.city = this.getDefaultShipping.city;
              this.shiptoSubmitted.city = shipcity;
              this.citiesShipto = citydata.data.data;
            }
          })
          .catch((cityerror) => {
            console.log(cityerror);
          });

        /***ship to end ****/
      })
      .catch((error) => {
        console.log(error);
        //this.order_Details = error;
      });
    // let shiptodetails = localStorage.getItem("ShippingToDetails") !== null ? JSON.parse(localStorage.getItem("ShippingToDetails")): [];
  },
  mounted() {},
  created() {
    this.user_token =
      this.$store.state.auth.CurrentUserLogin.token !== undefined
        ? this.$store.state.auth.CurrentUserLogin.token
        : this.$store.state.auth.CurrentUserLogin.CurrentUserLogin.token;

    let orders = {
      order_id: this.order_id,
      token: this.user_token,
    };

    this.orderDetails(orders)
      .then((res) => {
        console.log("res.data.data....", res.data.data);
        this.order_Details = res.data.data;
      })
      .catch((error) => {
        console.log(error);
        //this.order_Details = error;
      });
  },
  computed: {
    getUserOrders() {
      let Orders = this.store.state.userInfo.orderDetails;
      let alldata = {};
      if (Orders != "") {
        let getOrders = Orders.data;
        console.log(getOrders, "getOrders")
        ///  created_at format ////
        const created_at_date = dayjs(getOrders.created_at, {
          customParseFormat: "YYYY-MM-DD HH:mm:ss",
        });
        const OrderCreatedDate = created_at_date.format(
          "dddd, D MMMM YYYY h:mm A"
        );
        const updated_at_date = dayjs(getOrders.updated_at, {
          customParseFormat: "YYYY-MM-DD HH:mm:ss",
        });
        const OrderUpdatedDate = updated_at_date.format(
          "dddd, D MMMM YYYY h:mm A"
        );
        ///End ///

        alldata = {
          user_id: getOrders.user_id,
          order_id: getOrders.id,
          created_at: OrderCreatedDate,
          updated_at: OrderUpdatedDate,
          ip_address: getOrders.cust_ip,
          payment_type: getOrders.payment_type,
          price_total: getOrders.price_total,
          shipping_cost: getOrders.price_shipping,
          tax: getOrders.price_taxes,
          from_ship: getOrders.fromShip !== undefined ? getOrders.fromShip : "",
          to_ship: getOrders.toShip !== undefined ? getOrders.toShip : "",
          
          fromShipCity: getOrders?.fromShipCity,
          fromShipCountry: getOrders?.fromShipCountry,
          fromShipState: getOrders?.fromShipState,

          toShipCity: getOrders?.toShipCity,
          toShipCountry: getOrders?.toShipCountry,
          toShipState: getOrders?.toShipState

        };
        console.log(alldata, "alldata");
      }
      return alldata;
    },
    getOrders() {
      const created_at_dates = dayjs(this.order_Details.created_at, {
        customParseFormat: "YYYY-MM-DD HH:mm:ss",
      });
      const order_created_date = created_at_dates.format(
        "dddd, D MMMM YYYY h:mm A"
      );
      const updated_at_dates = dayjs(this.order_Details.updated_at, {
        customParseFormat: "YYYY-MM-DD HH:mm:ss",
      });
      const order_updated_date = updated_at_dates.format(
        "dddd, D MMMM YYYY h:mm A"
      );
      ///End ///
      let order_detail = this.order_Details;

      let alldata = {
        user_id: order_detail.user_id,
        order_id: order_detail.id,
        created_at: order_created_date,
        updated_at: order_updated_date,
        ip_address: order_detail.cust_ip,
        payment_type: order_detail.payment_type,
        price_total: order_detail.price_total,
        to_ship: order_detail.toShip !== undefined ? order_detail.toShip : "",
      };
      //console.log(alldata);

      return alldata;
    },
    getOrderItems() {
      let orders = this.order_Details;
      return orders;
    },
  },

  //GET_ORDER_DETAILS_ACTION
};
</script>
<style scoped>
.orderdetail {
  background: #edf1f3 !important;
  padding: 100px !important;
}
.btn-primary {
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  box-shadow: 0px 0px 0px 4px #edf1f38f !important;
}
</style>
