<template>
  <div class="inner_page_banner position-relative">
    <img class="w-100 h-100" v-bind:src="this.data" />

    <nav aria-label="breadcrumb" class="text-center">
      <h1
        class="font-weight-700 font-50 inner_page_banner_title text-capitalize green-color"
      >
        {{ this.page }}
      </h1>
      <ol class="breadcrumb mb-0 justify-content-center">
        <li
          class="breadcrumb-item green-color font-20 font-family-Roboto font-weight-400"
        >
          <a
            :href="baseURL"
            class="green-color text-decoration-none font-20 font-family-Roboto font-weight-400"
            >Home</a
          >
        </li>
        <li
          class="breadcrumb-item green-color font-20 font-family-Roboto font-weight-400"
        >
          <a
            ref="parent_page"
            :href="printingPage"
            class="green-color text-decoration-none font-20 font-family-Roboto font-weight-400 parent_page"
            >{{ parentSlug ? parentSlug : this.page }}</a
          >
        </li>

        <li
          v-if="childSlug"
          class="active breadcrumb-item green-color font-20 font-family-Roboto font-weight-400 child_page"
          aria-current="page"
        >
          <a
            ref="child_page"
            :href="childPage"
            class="green-color text-decoration-none font-20 font-family-Roboto font-weight-400"
            >{{
              this.route.params.pagename !== ""
                ? this.route.params.pagename
                : ""
            }}</a
          >
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  name: "InnerPageBanner",
  props: ["data", "page"],
  data() {
    return {
      baseURL: this.$baseurlWeb,
      printingPage: "",
      childPage: "",
      parentSlug: "",
      childSlug: localStorage.getItem("producttitle"),
      params: null,
    };
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
  methods: {
    getPageSlug() {
      let getproducttitle =
        localStorage.getItem("producttitle") !== null
          ? localStorage.getItem("producttitle")
          : "";
      return getproducttitle;
    },
  },
  computed: {
    getProductTitle() {
      let getproducttitle =
        localStorage.getItem("producttitle") !== null
          ? localStorage.getItem("producttitle")
          : "";
      return getproducttitle;
    },
  },
  created() {
    this.params = this.route.params.pagename;
  },
  mounted() {
    let cururl = location.href;
    if (cururl.indexOf("pages/printing/") > -1) {
      let url1 = cururl.split("/");
      if (
        url1[5] !== undefined &&
        url1[5].length > 0 &&
        url1[6] !== undefined &&
        url1[6].length > 0
      ) {
        this.parentSlug = url1[5];
        this.childSlug = url1[6];
        this.printingPage = this.$baseurlWeb + "pages/printing";
        this.childPage = this.printingPage + "/" + this.childSlug;
      }
      console.log("pagename..????- if..", this.route.params.pagename);
    } else {
      let pagename = this.route.name;
      console.log("pagename..????- else", this.route.params.pagename);
      let pageslug = this.route.params.pagename;
      this.parentSlug = pagename;
      this.childSlug = pageslug;
    }
  },
};
</script>
